import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support
import PropTypes from 'prop-types';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import ExpandLessIcon from '@material-ui/icons/ExpandLess';
//import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';


function MinusSquare(props) {
    return (
        <FiberManualRecordIcon style={{ width: 14, height: 14 }} />
    );
}

function PlusSquare(props) {
    return (
        <FiberManualRecordIcon style={{ width: 14, height: 14 }} />
    );
}


function TransitionComponent(props) {
    return (
        <animated.div >
            <Collapse {...props} />
        </animated.div>
    );
}

TransitionComponent.propTypes = {
    /**
     * Show the component; triggers the enter or exit states
     */
    in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18
    },
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles({
    root: {
        height: 264,
        flexGrow: 1,
        maxWidth: 400,
        fontFamily: "Montserrat,arial"
    },
});



export default function AdminProductCategoryList({ ProductCategoryData, setId }) {
    const classes = useStyles();

    function nothing() { };

    return (

        <TreeView
            defaultExpanded={[]}
            className={classes.root}
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}
            defaultEndIcon={<PlusSquare />}

        >
            {

                ProductCategoryData.map((item, index) => {
                    if (item.ProductCategoryParentID === 0) {

                        return (
                            <div key={index}   style={{ textDecoration: 'none' }}>
                                <StyledTreeItem onClick={() => { ProductCategoryData.filter(i => i.ProductCategoryParentID === item.ProductCategoryID).length > 0 ? nothing() : setId(item.ProductCategoryID)  }} nodeId={String(item.ProductCategoryID)} label={item.ProductCategoryName} >
                                    {
                                        ProductCategoryData.filter(i => i.ProductCategoryParentID === item.ProductCategoryID).map(
                                            (childItem, chiIndex) => {
                                                return (

                                                    <div  key={chiIndex} style={{ textDecoration: 'none' }}>
                                                        <StyledTreeItem onClick={() => setId(childItem.ProductCategoryID)} nodeId={String(childItem.ProductCategoryID)} label={childItem.ProductCategoryName} />
                                                    </div>

                                                )
                                            }
                                        )

                                    }
                                </StyledTreeItem>
                            </div>

                        )
                    } else {
                        return "";
                    }

                })
            }
        </TreeView>
    );
}
