import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AdminProductCategoryList from './AdminProductCategoryList';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";
import { getToken } from '../auth';
import { Link, useHistory } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import AdminImagePaper from './AdminImagePaper';
import Pagination from '@material-ui/core/Pagination'
import Checkbox from '@material-ui/core/Checkbox';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { getProductCategoryGridList, getAllProductList, deleteProductList } from "../../API/api.js";

export default function Index() {

    let pagesize = 50;

    let history = useHistory();

    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: 'transparent!important'
        },
    }));

    const [ListData, setListData] = useState([]);
    const [datas, setDatas] = useState([]);
    const classes = useStyles();

    const [currid, setCurrid] = useState(1);
    const [loadico, setloadico] = useState(false);

    const [paginationTotal, setPaginationTotal] = useState(0)
    const [sid, setsid] = useState(1)

    const [DeleteProductList, setDeleteProductList] = useState([]);

    const handleChange = (event) => {
        if (event.target.checked) {
            setDeleteProductList([...DeleteProductList, event.target.name]);
        } else {
            var array = [...DeleteProductList]; // make a separate copy of the array
            var index = array.indexOf(event.target.name)
            if (index !== -1) {
                array.splice(index, 1);
                setDeleteProductList([...array]);
            }
        }
      
        
    };

    //useEffect(() => {
    //    if (id === undefined) {
    //        setsid(1);
    //    } else {
    //        setsid(id);
    //    }

    //}, [id]);

    useEffect(() => {
        //console.log('sid',sid);
        if (sid !== undefined) {
            getAll(sid);
            setCurrid(1); //回到第一頁
        }
    }, [sid]);

    const getAll = (sid) => {
        setloadico(true);
        getAllProductList({ pagenumber: 1, pagesize: pagesize, productcategory: sid })
            .then(res => {
                setDatas(res.data);
                if (res.data[0] != undefined && res.data[0].TOTAL_ROW_COUNT != undefined) {
                    setPaginationTotal(res.data[0].TOTAL_ROW_COUNT);
                }
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => setloadico(false))

    }

    const handleChangePage = (event, newPage) => {
        setloadico(true);
        getAllProductList({ pagenumber: newPage, pagesize: pagesize, productcategory: sid })
            .then(res => {
                setDatas(res.data);
                if (res.data[0] != undefined && res.data[0].TOTAL_ROW_COUNT != undefined) {
                    setPaginationTotal(res.data[0].TOTAL_ROW_COUNT);
                }
                setDeleteProductList([]);
                setCurrid(newPage)
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => setloadico(false))
    }

    const getList = () => {

        getProductCategoryGridList()
            .then(res => {
                const valuesArray = res.data;

                setListData(valuesArray);

                if (valuesArray.length > 0) {
                    var firstItem = valuesArray.find(element => element.ProductCategoryParentID == 1 && element.ProductCategorySeq == 1);
                    if (firstItem != undefined && firstItem.ProductCategoryID) {
                        setsid(firstItem.ProductCategoryID)
                    }
                   
                }

            })
            .catch(err => {
                console.log('err', err);
            })
    }

    useEffect(() => {

        getList();
        setDeleteProductList([]);
    }, []);


    if (loadico && datas.length < 1) {
        return <div style={{ textAlign: "center", color: "#f50057" }}><h1>資料載入中......</h1><CircularProgress color="secondary" /></div>
    }

    const delProduct = () => {
        if (DeleteProductList.length>0) {
            setloadico(true);
            deleteProductList(DeleteProductList, getToken())
                .then(res => {
                    if (res.data) {
                        window.location.reload(false);
                    }
                })
                .catch(err => {
                    console.log('err', err);
                })
                .finally(() => {
                    setDeleteProductList([]);
                    setloadico(false);
                })
        }
    }

    return (
        <>
            <Backdrop open={loadico} className={classes.backdrop}>
                <CircularProgress color="secondary" />
            </Backdrop>

            <h1>產品管理</h1>
            <div style={{ marginLeft: '8px'}}>

                <div style={{ paddingTop: '28px' }}>
                    <Link style={{ textDecoration: 'none', color: '#fff' }} to={"/AdminGotonWeb/PCM/Add"}>
                        <Button variant="contained" size="large" startIcon={<AddCircleIcon />} >新增產品</Button>
                    </Link>
                </div>

                <div style={{ paddingTop: '28px' }}>
                    <Grid container
                        justify="flex-start"
                        alignItems="flex-start"
                        style={{ paddingTop: '28px' }}
                        spacing={2}>
                        <Grid item lg={4} md={12} >
                            <h2 style={{ color:'#3f51b5' }} >STEP 1 選擇產品分類</h2>
                            <div style={{ paddingTop: '28px' }}>
                                {<AdminProductCategoryList ProductCategoryData={ListData} setId={setsid} />}
                            </div>
                        </Grid>
                        <Grid item lg={8} md={12}>
                            <h2 style={{ color: '#3f51b5' }}>STEP 2 選擇修改產品</h2>
                            <div style={{ display: (datas.length > 0) ? 'block' : 'none' }}>
                            <Button style={{ marginLeft: '8px' }} variant="contained" color="secondary" size="large" onClick={delProduct} startIcon={<DeleteForeverIcon />}>
                                    刪除選擇項目
                            </Button>
                            </div>
                            <div style={{ paddingTop: '28px' }}>
                                <Grid container
                                    direction="column"
                                    alignItems="center"
                                    justify="center"
                                    spacing={3}>
                                    <Grid item >
                                        <Grid container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="flex-start"
                                            spacing={2}
                                        >
                                            {datas.map((item, index) => (
                                                <div key={item.ProductID}>
                                                    <Checkbox name="checkDEL" onChange={handleChange} name={item.ProductID} />
                                                    <AdminImagePaper ImageData={`${item.ProductImageData}`} ProductID={`${item.ProductID}`} ProductName={`${item.ProductName}`} ProductEnabled={`${item.ProductEnabled}`} />
                                                </div>
                                            ))}
                                        </Grid>

                                    </Grid>
                                    <Grid item style={{ height: '80px' }} >
                                        <Pagination count={parseInt(Math.ceil((paginationTotal) / pagesize), 10)} page={currid} color="secondary" onChange={handleChangePage} />
                                    </Grid>

                                </Grid>

                            </div>

                        </Grid>
                    </Grid>
                </div>

            </div>
         
        </>
    );
}