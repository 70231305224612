import React from 'react';

import { Switch, Route } from 'react-router-dom';
import Index from './CM/Index';
import Add from './CM/Add';
import Edit from './CM/Edit';


export default function CM() {
    return (
        <>
            <Switch>
                <Route exact path='/AdminGotonWeb/CM' component={Index} />
                <Route path='/AdminGotonWeb/CM/Add' component={Add} />
                <Route path='/AdminGotonWeb/CM/Edit/:id' component={Edit} />
            </Switch>
        </>

    );
}