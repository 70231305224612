import React from 'react';
import './Pages.css';
import ButtonBlock from './ButtonBlock';

export default function Services() {
    return (
        <>
            <div className='Services'>
               
                <div className='Services2'>
                </div>
                <div className='Services3'>
                    <div className="Services3title">
                        OEM / ODM
                    </div>
                    <div className="Services3content">
                        <div>
                            GoTon have produced 2-way radio audio accessories for more than 20 years.
                        </div>
                        <div>
                            With GoTon’s excellent design capability and numerous molds and tools,
                        </div>
                        <div>
                            whatever OEM or ODM, customers can get nice One-stop services at GoTon.
                        </div>
                        <div>
                            We have complete/flexible production lines. From materials/parts in-coming,
                        </div>
                        <div>
                            in-processing, and products outgoing, we control each QC points to keep the quality assurance (QA).
                        </div>
                        <div>
                            GoTon commit what we promise customers.
                         </div>
                        <div>
                            Choose GoTon, and you will find that the success is sure enough.
                         </div>
                    </div>
                </div>

            </div>

            <ButtonBlock />
        </>
    );
}