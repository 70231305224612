import React, { useState } from 'react';

import { DropdownUL, DropdownLink, Dropdownli } from '../Style'

function Dropdown(props) {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    return (
        <>
            <DropdownUL
                onClick={handleClick}
                isClick={click}
            >
                {
                    props.menuDatas.map(
                        (item, index) => {
                            return (
                                <Dropdownli key={index}>
                                    <DropdownLink
                                        to={'/Products/' + item.ProductCategoryID + '/' + item.ProductCategoryParentID}
                                        onClick={() => setClick(false)}
                                    >
                                        {item.ProductCategoryName}
                                    </DropdownLink>
                                </Dropdownli>
                                );
                        }
                    )
                }
            </DropdownUL>
        </>
    )
}

export default Dropdown;