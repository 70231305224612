import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from '@material-ui/core/Button';
import Paper from "@material-ui/core/Paper";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { v4 as uuidv4 } from 'uuid';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
    table: {
        minWidth: 650
    }
});


function createData(Pkid, ProductId,Spec, SpecSeq, SpecEnabled) {
    return { Pkid,ProductId,Spec, SpecSeq, SpecEnabled };
}

const rows = [
    createData(uuidv4(),'', '', 1, 1),
];



const SetSpec = (dataRow, Pkid, Spec) => {
    let newArray = dataRow.map((x, index) => {
        if (x.Pkid === Pkid) {
            x.Spec = Spec;
        }
        return x;
    });

    return newArray;
};

const SetSpecSeq = (dataRow, Pkid, SpecSeq) => {
    let newArray = dataRow.map((x, index) => {
        if (x.Pkid === Pkid) {
            x.SpecSeq = SpecSeq;
        }
        return x;
    });

    return newArray;
};

const Add = (dataRow) => {
    return [...dataRow, createData(uuidv4(),'', '', 1, 1)];
};

const LoadAdd = (dataRow,item) => {
    return [...dataRow, ...item];
};

const Delete = (dataRow, Pkid) => {
    const newArray = dataRow.filter(x => x.Pkid != Pkid);
    return newArray;
};

export default function SpacTable({ getSpec, specList, mode }) {

    const [files, setFiles] = useState([...rows]) ;

    useEffect(() => {
        getSpec([files]);       
    }, [files]);

    useEffect(() => {
        if (specList != undefined) {
                    
            if (specList != undefined && specList.length>0 && specList[0].ProductId != undefined) {
               
                //console.log('Load SPEC', specList);
                setFiles([...specList]);
            }
        }
      
    }, [specList]);


    const classes = useStyles();

    return (
        <>

            <div style={{ marginTop: '20px' }}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow style={{ height: '60px', backgroundColor: '#03fc8c' }}>
                                <TableCell align="center" style={{ fontSize: '20px', fontWeight: "bold" }}>規格</TableCell>
                                <TableCell align="center" style={{ fontSize: '20px', fontWeight: "bold" }}>排序</TableCell>
                                <TableCell align="center" style={{ fontSize: '20px', fontWeight: "bold" }}>
                                    <Button variant="contained" color="primary" size="medium" onClick={() => setFiles(Add([...files]))} startIcon={<AddCircleOutlineIcon />}>
                                        新增規格
            </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {files.map((row) => (
                                <TableRow key={row.Pkid}>
                                    <TableCell>
                                        <TextField
                                            style={{ width: '100%' }}
                                            value={row.Spec}
                                            onChange={e => (setFiles(SetSpec([...files], row.Pkid, e.target.value)))}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            value={row.SpecSeq}
                                            type="number"
                                            onChange={e => (setFiles(SetSpecSeq([...files], row.Pkid, e.target.value)))}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button variant="contained" size="small" startIcon={<DeleteForeverIcon />} color="secondary" onClick={() => setFiles(Delete([...files], row.Pkid))} component="span">
                                            刪除
                                    </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}
