import React, { useState, useEffect } from 'react';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
    useParams
} from "react-router-dom";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { getProductCategoryParentList, getProductCategoryById, editProductCategory, checkProductCategoryExist, deleteProductCategory } from "../../API/api.js";
import { getToken, IsLogin } from '../auth';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '600px',
        },
        formControl: {
            margin: theme.spacing(1),

            minWidth: '600px',
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
}));


export default function Edit() {
    let { id } = useParams()

    const classes = useStyles();

    const [loadico, setloadico] = useState(false)

    let history = useHistory();

    const [values, setValues] = useState({
        ProductCategoryID: 0,
        ProductCategoryParentID: 0,
        OLDProductCategoryParentID:0,
        ProductCategoryName: '',
        OLDProductCategoryName: '',
        ProductCategorySeq: 0,
        ProductCategoryEnabled: 1
    });

    const handleProductCategoryParentIDChange = (event) => {
        setValues({ ...values, ProductCategoryParentID: event.target.value });
    };

    const handleName = event => {
        setValues({ ...values, ProductCategoryName: event.target.value });
    };

    const handleSeq = event => {
        setValues({ ...values, ProductCategorySeq: event.target.value });
    };

    const handleEnabledChange = (event) => {
        setValues({ ...values, ProductCategoryEnabled: event.target.value });
    };

    const [ListData, setListData] = useState([])

    const getList = () => {

        getProductCategoryParentList()
            .then(res => {
                const valuesArray = res.data;
                setListData(valuesArray);

            })
            .catch(err => {
                console.log('err', err);
            })
      
    }

    const loadDate = (id) => {

        getProductCategoryById(id)
            .then(res => {
                const result = res.data;
                if (id) {
                    setValues(
                        {
                            ProductCategoryID: result.ProductCategoryID,
                            ProductCategoryParentID: result.ProductCategoryParentID,
                            OLDProductCategoryParentID: result.ProductCategoryParentID,
                            ProductCategoryName: result.ProductCategoryName,
                            OLDProductCategoryName: result.ProductCategoryName,
                            ProductCategorySeq: result.ProductCategorySeq,
                            ProductCategoryEnabled: result.ProductCategoryEnabled
                        }
                    );
                }


            })
            .catch(err => {
                console.log('err', err);
            })
    }


    useEffect(() => {

        try {
            setloadico(true);
            getList();
           
        } catch (error) {
            console.error(error);
            
        } finally {
            setloadico(false)
        }
      

    }, []);

    useEffect(() => {

        loadDate(id);

    }, [id]);


    const submit = e => {

        if (!IsLogin()) {
            alert('登入時間過期請重新登入');
            return;
        }

        e.preventDefault();
        if (values.ProductCategoryParentID != values.OLDProductCategoryParentID || values.ProductCategoryName != values.OLDProductCategoryName) {
            checkProductCategoryExist(values.ProductCategoryName, values.ProductCategoryParentID)
                .then(res => {
                    if (res.data) {
                        alert('目錄已存在');
                    } else {
                        save();
                    }
                })
                .catch(err => {
                    setloadico(false)
                    console.log('err', err);
                })
        }
        else {
            save();
        }
       
      
    }

    const save = () => {
        if (values.ProductCategoryID) {
            setloadico(true);
            editProductCategory(values, getToken())
                .then(res => {
                    if (res.data) {
                        history.push('/AdminGotonWeb/CM');
                    }
                })
                .catch(err => {
                    console.log('err', err);
                })
                .finally(() => setloadico(false))
        }
    };

    const delProductCategory = () => {
        var yes = window.confirm('你確定刪除該目錄及目錄下所有商品嗎？');

        if (yes) {
            if (values.ProductCategoryID) {
                setloadico(true);
                deleteProductCategory(values, getToken())
                    .then(res => {
                        if (res.data) {
                            history.push('/AdminGotonWeb/CM');
                        }
                    })
                    .catch(err => {
                        console.log('err', err);
                    })
                    .finally(() => setloadico(false))
            }
        } 
    }

    return (
        <>
            <Backdrop open={loadico} className={classes.backdrop}>
                <CircularProgress color="secondary" />
            </Backdrop>
            <form className={classes.root} autoComplete="off" onSubmit={submit}>
                <h2>修改產品目錄</h2>
                <TextField
                    id="ProductCategoryId"
                    label="產品類別編號"
                    helperText="這裡是系統自動產生不用修改，只供辨示"
                    value={id}
                    disabled
                />
                <div style={{ margin: '8px' }}>
                    <FormControl>
                        <FormHelperText style={{ fontSize: '16px', color: 'Black', fontWeight: 'bold' }}>選擇所屬的目錄，如果是第一層目錄請選擇「根目錄」：</FormHelperText>
                        <Select
                            value={values.ProductCategoryParentID}
                            onChange={handleProductCategoryParentIDChange}
                            disabled={values.ProductCategoryParentID == 0}
                        >
                            <MenuItem value={0}>根目錄</MenuItem>
                            {
                                ListData.map(
                                    (childItem, chiIndex) => {
                                        return (
                                            <MenuItem key={chiIndex} value={childItem.ProductCategoryID}>{childItem.ProductCategoryName}</MenuItem>
                                        )
                                    }
                                )
                            }

                        </Select>
                    </FormControl>
                </div>
                <TextField
                    id="ProductCategoryName"
                    label="產品類別名稱"
                    helperText="這裡輸入「產品目錄名稱」或「子目錄名稱」"
                    onChange={handleName}
                    value={values.ProductCategoryName}
                    required
                />
                <TextField
                    id="ProductCategorySeq"
                    label="排序"
                    type="number"
                    helperText="目錄順序由小到大排序"
                    onChange={handleSeq}
                    value={values.ProductCategorySeq}
                    required
                />
                <div style={{ margin: '8px' }}>
                    <FormControl>

                        <Select
                            value={values.ProductCategoryEnabled}
                            onChange={handleEnabledChange}
                        >
                            <MenuItem value={1}>啟用</MenuItem>
                            <MenuItem value={0}>停用</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{ marginLeft: '8px', marginTop: '46px' }}>

                    <Button variant="contained" color="primary" size="large" type="submit" startIcon={<SaveIcon />}>
                        儲存
                    </Button>

                    <Button style={{ marginLeft: '8px' }} variant="contained" color="secondary" size="large" onClick={delProductCategory } startIcon={<DeleteForeverIcon />}>
                        刪除
                    </Button>

                    <Link style={{ textDecoration: 'none', color: '#fff' }} to={"/AdminGotonWeb/CM"}>
                        <Button variant="contained" color="secondary" size="large" style={{ marginLeft: '8px' }} startIcon={<CancelIcon />}>
                            取消
                        </Button>
                    </Link>

                </div>

            </form>


        </>

    );
}