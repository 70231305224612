import axios from 'axios';

const webURL = 'https://www.goton.com.tw';


const productRequest = axios.create({
    baseURL: webURL+'/api/Product/'
});

const authRequest = axios.create({
    baseURL: webURL +'/api/Auth/'
});

const contactRequest = axios.create({
    baseURL: webURL + '/api/Contact/'
});

//登入相關的api

//Username
//Password
export const Login = data => authRequest.post('/Login', data);
export const getAllUser = (token) => authRequest.get('/GetAllUser', { headers: { 'Authorization': `Bearer ${token}` } });
export const setUser = (data, token) => authRequest.post('/SetUser', data, { headers: { 'Authorization': `Bearer ${token}` } });

//link相關
export const getHomeImageSwiper2Link = data => productRequest.get('/GetHomeImageSwiper2Link');
export const setHomeImageSwiper2Link = data => productRequest.get('/SetHomeImageSwiper2Link');
//contact相關
export const getContactSystemCode = data => contactRequest.get('/GetContactSystemCode');

//SetSystemCode相關

export const setSystemCode = (data, token) => contactRequest.post('/SetSystemCode', data, { headers: { 'Authorization': `Bearer ${token}` } });
// 商品相關 api
export const getProductList = data => productRequest.post('/GetProductList', data);
export const getAllProductList = data => productRequest.post('/GetAllProductList', data);


export const getRelatedProductList = data => productRequest.post('/GetRelatedProductList', data);

export const getProductCategoryMenuList = () => productRequest.get('/GetProductCategoryMenuList');

export const getProductCategoryList = () => productRequest.get('/GetProductCategoryList');

export const getProductCategoryGridList = () => productRequest.get('/GetProductCategoryGridList');

export const getProductCategoryById = data => productRequest.get('/GetProductCategoryById?Id=' + data);

export const getProductCategoryParentList = () => productRequest.get('/GetProductCategoryParentList');

export const getProductCategoryEndPointList = () => productRequest.get('/GetProductCategoryEndPointList');

export const getProductDetail = data => productRequest.get('/GetProductDetail?productId=' + data);

export const addProductCategory = (data, token) => productRequest.post('/AddProductCategory', data, { headers: { 'Authorization': `Bearer ${token}` } });

export const editProductCategory = (data, token) => productRequest.post('/EditProductCategory', data, { headers: { 'Authorization': `Bearer ${token}` } });

export const deleteProductCategory = (data, token) => productRequest.post('/DeleteProductCategory', data, { headers: { 'Authorization': `Bearer ${token}` } });

export const addProduct = (data, token) => productRequest.post('/AddProduct', data, { headers: { 'Authorization': `Bearer ${token}` } });

export const editProduct = (data, token) => productRequest.post('/EditProduct', data, { headers: { 'Authorization': `Bearer ${token}` } });

export const deleteProductList = (data, token) => productRequest.post('/DeleteProductList', data, { headers: { 'Authorization': `Bearer ${token}` } });

export const deleteProduct = (data, token) => productRequest.post('/DeleteProduct', data, { headers: { 'Authorization': `Bearer ${token}` } });

export const getProductById = data => productRequest.get('/GetProductById?Id=' + data);

export const checkProductExist = (ProductName) => productRequest.get('/CheckProductExist?ProductName=' + ProductName);

export const checkProductCategoryExist = (ProductCategoryName, ProductCategoryParentID) => productRequest.get('/CheckProductCategoryExist?ProductCategoryName=' + ProductCategoryName + '&ProductCategoryParentID=' + ProductCategoryParentID);

//Contact 相關

export const sendMail = data => authRequest.post('/SendMail', data);