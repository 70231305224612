import React from 'react';

import { Switch, Route } from 'react-router-dom';
import Index from './PCM/Index';
import Add from './PCM/Add';
import Edit from './PCM/Edit';

export default function PCM() {
    return (
        <>
            <Switch>
                <Route exact path='/AdminGotonWeb/PCM' component={Index} />
                <Route path='/AdminGotonWeb/PCM/Add' component={Add} />
                <Route path='/AdminGotonWeb/PCM/Edit/:id' component={Edit} />
            </Switch>
        </>

    );
}