import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import ProductCategoryGrid from './ProductCategoryGrid';

import { getProductCategoryGridList } from "../../API/api.js";
import AddCircleIcon from '@material-ui/icons/AddCircle';

export default function Index() {
    const [ListData, getListData] = useState([])
    const getList = () => {

        getProductCategoryGridList()
            .then(res => {
                const valuesArray = res.data;

                getListData(valuesArray);

            })
            .catch(err => {
                console.log('err', err);
            })
    }

    useEffect(() => {

        getList();

    }, []);

    return (
        <>
           
            <h1>產品目錄管理</h1>
          
            <div style={{ marginLeft: '8px'}}>

                <div style={{ paddingTop: '28px' }}>
                    <Link style={{ textDecoration: 'none', color: '#fff', margin: '20' }} to={"/AdminGotonWeb/CM/Add"}>
                        <Button variant="contained" size="large" startIcon={<AddCircleIcon />}>新增產品目錄</Button>
                    </Link>
                </div>
               
                <div style={{ paddingTop: '28px' }}>
                    <ProductCategoryGrid ProductCategoryData={ListData} />
                </div>
               
            </div>
         
        </>
    );
}