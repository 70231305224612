import styled from 'styled-components'
import { Link } from 'react-router-dom';

export const DropdownUL = styled.ul`
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    opacity: .8;
    width: 200px;
    position: absolute;
    top: 80px;
    list-style: none;
    text-align: start;
    display: ${ props => props.isClick ? 'none' :'block'};
`

export const DropdownLink = styled(Link)`
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #ffffff;
    padding: 16px;
    &:hover  {
       color: chartreuse;
    }
`

export const Dropdownli = styled.li`
    cursor: pointer;
    &:hover  {
      background: rgb(28, 27, 27);
    }
`

export const CaretDown = styled.i`
  @media screen and (max-width: 960px) {
    display: none;
  }
`

export const NavbarItem = styled.nav`
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    opacity: .8;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    position: sticky;
    top: 0;
    z-index: 999;
`

export const NavbarLogo = styled(Link)`
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;

    &:hover  {
        background-color: rgb(28, 27, 27);
        color:  chartreuse;
        transition: all 0.2s ease-out;
    }   

    @media screen and (max-width: 960px) {
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(25%, 50%);
    }

`

export const MenuIcon = styled.div`
    display: none;
    @media screen and (max-width: 960px) {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(-100%, 60%);
                font-size: 1.8rem;
                cursor: pointer;
    }

`

export const NavItem = styled.li`
     display: flex;
     align-items: center;
     height: 80px;
`

export const NavLinks = styled(Link)`
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;

    &:hover  {
        background-color: rgb(28, 27, 27);
        color:  chartreuse;
        border-radius: 4px;
        
        transition: all 0.2s ease-out;
    }

    @media screen and (max-width: 960px) {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
         &:hover  {
                background-color: #1888ff;
                border-radius: 0;
            }
    }
`