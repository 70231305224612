import React, { useState, useEffect } from 'react';
import { Link, useHistory  } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { getToken, IsLogin } from '../auth';

import { getProductCategoryParentList, addProductCategory, checkProductCategoryExist } from "../../API/api.js";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '600px',
        },
        formControl: {
            margin: theme.spacing(1),
            
            minWidth: '600px',
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
}));



export default function Add() {

    const classes = useStyles();

    let history = useHistory();

    const [values, setValues] = useState({
        ProductCategoryParentID: 0,
        ProductCategoryName: '',
        ProductCategorySeq: 0,
        ProductCategoryEnabled: 1
    });

    const handleProductCategoryParentIDChange = (event) => {
        setValues({ ...values, ProductCategoryParentID: event.target.value });
    };

    const handleName = event => {
        setValues({ ...values, ProductCategoryName: event.target.value });
    };

    const handleSeq = event => {
        setValues({ ...values, ProductCategorySeq: event.target.value });
    };

    const handleEnabledChange = (event) => {
        setValues({ ...values, ProductCategoryEnabled: event.target.value });
    };

    const [ListData, getListData] = useState([])

    const [loadico, getloadico] = useState(false)

    const getList = () => {

        getProductCategoryParentList()
            .then(res => {
                const valuesArray = res.data;
               
                getListData(valuesArray);

            })
            .catch(err => {
                console.log('err', err);
            })
    }

    useEffect(() => {

        getList();

    }, []);


    const submit = e => {

        if (!IsLogin()) {
            alert('登入時間過期請重新登入');
            return;
        }

        e.preventDefault();

        checkProductCategoryExist(values.ProductCategoryName, values.ProductCategoryParentID)
            .then(res => {
                if (res.data) {
                    alert('目錄已存在');
                } else {
                    if (values.ProductCategoryName.length > 0) {
                        getloadico(true);
                        addProductCategory(values, getToken())
                            .then(res => {
                                if (res.data) {
                                    history.push('/AdminGotonWeb/CM');
                                }
                            })
                            .catch(err => {
                                console.log('err', err);
                            })
                            .finally(() => getloadico(false))
                    }
                }
            })
            .catch(err => {
                getloadico(false)
                console.log('err', err);
            })


    }


    return (
        <>
            <Backdrop open={loadico} className={classes.backdrop}>
                <CircularProgress color="secondary" />
            </Backdrop>
            <form className={classes.root} autoComplete="off" onSubmit={submit}>
                <h2>新增產品目錄</h2>
                <div style={{ margin: '8px' }}>
                    <FormControl>
                        <FormHelperText style={{ fontSize: '16px', color: 'Black', fontWeight: 'bold' }}>選擇所屬的目錄，如果是第一層目錄請選擇「根目錄」：</FormHelperText>
                        <Select
                            value={values.ProductCategoryParentID}
                            onChange={handleProductCategoryParentIDChange}
                        >
                            <MenuItem value={0}>根目錄</MenuItem>
                            {
                                ListData.map(
                                    (childItem, chiIndex) => {
                                        return (
                                            <MenuItem key={chiIndex } value={childItem.ProductCategoryID}>{childItem.ProductCategoryName}</MenuItem>
                                        )
                                    }
                                )
                            }

                        </Select>
                    </FormControl>
                </div>
                <TextField
                    id="ProductCategoryName"
                    label="產品類別名稱"
                    helperText="這裡輸入「產品目錄名稱」或「子目錄名稱」"
                    onChange={handleName}
                    value={values.ProductCategoryName}
                    required
                />
                <TextField
                    id="ProductCategorySeq"
                    label="排序"
                    type="number"
                    helperText="目錄順序由小到大排序"
                    onChange={handleSeq}
                    value={values.ProductCategorySeq}
                    required
                />
                <div style={{margin:'8px'}}>
                    <FormControl>
                       
                        <Select
                            value={values.ProductCategoryEnabled}
                            onChange={handleEnabledChange}
                        >
                            <MenuItem value={1}>啟用</MenuItem>
                            <MenuItem value={0}>停用</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{ marginLeft: '8px', marginTop: '46px' }}>
                  
                    <Button variant="contained" color="primary" size="large" type="submit" startIcon={<SaveIcon />}>
                        儲存
                    </Button>
                   
                    <Link style={{ textDecoration: 'none', color: '#fff' }} to={"/AdminGotonWeb/CM"}>
                        <Button variant="contained" color="secondary" size="large" style={{ marginLeft: '8px' }} startIcon={<CancelIcon />}>
                            取消
                        </Button>
                    </Link>
                   
                </div>
              
            </form>

        
        </>
    );
}