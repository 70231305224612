import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@material-ui/core/Pagination'
import Grid from '@material-ui/core/Grid';
import ButtonBlock from './ButtonBlock';
import ImagePaper from './ImagePaper';
import ProductCategoryList from './ProductCategoryList';
import { getProductCategoryList, getProductList } from "../API/api.js";
import './Pages.css';

export default function Products() {

    let { id } = useParams(1)
    let { parentId } = useParams(1)

    let pagesize = 20;

    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: 'transparent!important'
        },
    }));

    const classes = useStyles();

    const [loadico, setloadico] = useState(false)

    const [datas, setDatas] = useState([])

    const [currid, setCurrid] = useState(1)

    const [paginationTotal, setPaginationTotal] = useState(0)

    const [ListData, setListData] = useState([])
    
    const [sid, setsid] = useState()
   

    const getList = () => {

        getProductCategoryList()
            .then(res => {
                const valuesArray = res.data;
              
                setListData(valuesArray);

            })
            .catch(err => {
                console.log('err', err);
            })
    }

    useEffect(() => {
        if (id === undefined) {
            setsid(1);
        } else {
            setsid(id);
        }
       
    }, [id]);

    useEffect(() => {

        if (sid !== undefined) {
            getAll(sid);
            setCurrid(1); //回到第一頁
        }

    }, [sid]);

    useEffect(() => {
        //const handleScroll = () => {
        //    const currentScrollY = window.scrollY;
        //    prevScrollY.current = currentScrollY;
        //    sety(prevScrollY.current/100);
        //};

        //window.addEventListener("scroll", handleScroll, { passive: true });

        //return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {

        getList();

    }, []);


    const getAll = (sid) => {
        setloadico(true);
        getProductList({ pagenumber: 1, pagesize: pagesize, productcategory: sid })
            .then(res => {
                setDatas(res.data);
                setPaginationTotal(res.data[0].TOTAL_ROW_COUNT);
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => setloadico(false))

    }



    const handleChangePage = (event, newPage) => {
        setloadico(true);
        getProductList({ pagenumber: newPage, pagesize: pagesize, productcategory: sid })
            .then(res => {
                setDatas(res.data);
                setPaginationTotal(res.data[0].TOTAL_ROW_COUNT);
                setCurrid(newPage)
            })
            .catch(err => {
                console.log(err);
            })
            .finally(()=>setloadico(false))
    }

    if (loadico && datas.length < 1) {
        return <h1></h1>
    }
    

    return (
        <>
            <Backdrop open={loadico}  className={classes.backdrop}>
                <CircularProgress color="secondary" />
            </Backdrop>

            <div className="Product">
                <div className="div90">
                    <h1>Product Category</h1>
                    <div>
                        <Grid container
                            justify="flex-start"
                            alignItems="flex-start"
                            spacing={2}>
                            <Grid item lg={4} md={12} >
                                {<ProductCategoryList ProductCategoryData={ListData} Id={id} ParentId={parentId} />}
                            </Grid>
                            <Grid item lg={8} md={12}>
                                <Grid container
                                    direction="column"
                                    alignItems="center"
                                    justify="center"
                                    spacing={3}>
                                    <Grid item >
                                        <Grid container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="flex-start"
                                            spacing={2}
                                        >
                                            {datas.map((item, index) => (
                                                <ImagePaper key={item.ProductID} ImageData={`${item.ProductImageData}`} ProductID={`${item.ProductID}`} ProductName={`${item.ProductName}`} Id={`${id}`} ParentId={`${parentId}`} />
                                            ))}
                                        </Grid>

                                    </Grid>
                                    <Grid item style={{ height: '80px' }} >
                                        <Pagination count={parseInt(Math.ceil((paginationTotal) / pagesize), 10)} page={currid} color="secondary" onChange={handleChangePage} />
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
          

           
            </>
      
    );


}


