import React, { useState, useEffect } from 'react';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
    useParams
} from "react-router-dom";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

import { getProductCategoryEndPointList, getProductById, editProduct, checkProductExist, deleteProduct } from "../../API/api.js";
import { getToken, IsLogin } from '../auth';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Dropzone from './Dropzone';
import SpecTable from './SpecTable';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '600px',
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: '600px',
        }
    },
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
}));

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #fff;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;


export default function Edit() {
    let { id } = useParams()

    const classes = useStyles();

    const [loadico, setloadico] = useState(false)

    let history = useHistory();

    const [values, setValues] = useState({
        ProductID: '',
        ProductCategoryID: 0,
        ProductName: '',
        OLDProductName: '',
        ProductSeq: 0,
        ProductEnabled: 1,
        IsCategoryType: 0,
        IsAutoRedirection: 0,
        ShowSpec: 1,
        File1: '',
        File2: '',
        File3: '',
        File4: '',
        Spec: []
    });

    const [file1, setFile1] = useState([]);
    const [file2, setFile2] = useState([]);
    const [file3, setFile3] = useState([]);
    const [file4, setFile4] = useState([]);

    const [Orifile1, setOriFile1] = useState({isDelete:false,img:''});
    const [Orifile2, setOriFile2] = useState({ isDelete:false,img: '' });
    const [Orifile3, setOriFile3] = useState({ isDelete:false,img: '' });
    const [Orifile4, setOriFile4] = useState({ isDelete:false,img: '' });

    const [spec, setSpec] = useState([]);

    useEffect(() => {
        //console.log("ok1", file1);
        setValues({ ...values, File1: file1 });
    }, [file1]);

    useEffect(() => {
        //console.log("ok2", file2);
        setValues({ ...values, File2: file2 });
    }, [file2]);

    useEffect(() => {
        //console.log("ok3", file3);
        setValues({ ...values, File3: file3 });
    }, [file3]);

    useEffect(() => {
        //console.log("ok4", file4);
        setValues({ ...values, File4: file4 });
    }, [file4]);

    useEffect(() => {
        if (spec.length > 0) {
            setValues({ ...values, Spec: spec });
        }
    }, [spec]);

    const handleProductIDChange = (event) => {
        setValues({ ...values, ProductID: event.target.value });
    };

    const handleProductCategoryIDChange = (event) => {
        setValues({ ...values, ProductCategoryID: event.target.value });
    };

    const handleName = event => {
        setValues({ ...values, ProductName: event.target.value });
    };

    const handleSeq = event => {
        setValues({ ...values, ProductSeq: event.target.value });
    };

    const handleEnabledChange = (event) => {
        setValues({ ...values, ProductEnabled: event.target.value });
    };

    const handleIsCategoryType = (event) => {
        setValues({ ...values, IsCategoryType: event.target.value });
    };

    const handleIsAutoRedirection = (event) => {
        setValues({ ...values, IsAutoRedirection: event.target.value });
    };

    const handleShowSpec = (event) => {
        setValues({ ...values, ShowSpec: event.target.value });
    };

    const [ListData, setListData] = useState([])

    const getList = () => {

        getProductCategoryEndPointList()
            .then(res => {
                const valuesArray = res.data;

                setListData(valuesArray);

            })
            .catch(err => {
                console.log('err', err);
            })
    }

    useEffect(() => {
        //console.log('getList');
        getList();

    }, []);


    const loadDate = (id) => {

        getProductById(id)
            .then(res => {
                const result = res.data;
                if (result) {
                    setValues(
                        {
                            ProductID: result.ProductID,
                            ProductCategoryID: result.ProductCategory,
                            ProductName: result.ProductName,
                            OLDProductName: result.ProductName,
                            ProductSeq: result.ProductSeq,
                            ProductEnabled: result.ProductEnabled,
                            IsCategoryType: result.IsCategoryType,
                            IsAutoRedirection: result.IsAutoRedirection,
                            ShowSpec: result.ShowSpec,
                            Spec: result.SpecList,
                        }
                    );


                    if (result.ImageList != undefined && result.ImageList.length) {

                        result.ImageList.forEach((item, index) => {
                            if (item.ProductImageType == 1) {
                                setOriFile1({ isDelete: false, img: item.ProductImageData });
                            }
                            if (item.ProductImageType == 2) {
                                setOriFile2({ isDelete: false, img: item.ProductImageData });
                            }
                            if (item.ProductImageType == 3) {
                                setOriFile3({ isDelete: false, img: item.ProductImageData });
                            }
                            if (item.ProductImageType == 4) {
                                setOriFile4({ isDelete: false, img: item.ProductImageData });
                            }
                        });

                        //if (result.ImageList[0] != undefined) {
                        //}
                        //if (result.ImageList[1] != undefined) {
                        //    setOriFile2({ isDelete: false, img: result.ImageList[1].ProductImageData });
                        //}
                        //if (result.ImageList[2] != undefined) {
                        //    setOriFile3({ isDelete: false, img: result.ImageList[2].ProductImageData });
                        //}
                        //if (result.ImageList[3] != undefined) {
                        //    setOriFile4({ isDelete: false, img: result.ImageList[3].ProductImageData });
                        //}
                    }
                }


            })
            .catch(err => {
                console.log('err', err);
            })
    }


    useEffect(() => {
        loadDate(id);

    }, [id]);


    const submit = e => {

        if (!IsLogin()) {
            alert('登入時間過期請重新登入');
            return;
        }

        e.preventDefault();

        if (values.ProductCategoryID === 0) {
            alert('請選擇產品分類目錄');
            return;
        }

        if (values.OLDProductName != values.ProductName) {
            checkProductExist(values.ProductName)
                .then(res => {
                    if (res.data) {
                        alert('商品名稱已存在');
                    } else {
                        save();
                    }
                })
                .catch(err => {
                    setloadico(false)
                    console.log('err', err);
                })
        } else {
            save();
        }


    }

    const save = () => {
        if (values.ProductName.length > 0 && values.ProductID.length > 0) {
            setloadico(true);
            const formData = new FormData();
            formData.append('ProductID', values.ProductID);
            formData.append('ProductCategoryID', values.ProductCategoryID);
            formData.append('ProductName', values.ProductName);
            formData.append('ProductSeq', values.ProductSeq);
            formData.append('ProductEnabled', values.ProductEnabled);
            formData.append('IsCategoryType', values.IsCategoryType);
            formData.append('IsAutoRedirection', values.IsAutoRedirection);
            formData.append('ShowSpec', values.ShowSpec);
            formData.append('File1', values.File1);
            formData.append('File2', values.File2);
            formData.append('File3', values.File3);
            formData.append('File4', values.File4);
            formData.append('File2isDelete', Orifile2.isDelete);
            formData.append('File3isDelete', Orifile3.isDelete);
            formData.append('File4isDelete', Orifile4.isDelete);

            if (values.Spec != undefined && values.Spec.length > 0 && values.Spec[0] != undefined) {

                values.Spec[0].forEach((item, index) => {
                    formData.append('SpecList[' + index + '].Pkid', item.UUID);
                    formData.append('SpecList[' + index + '].Spec', item.Spec);
                    formData.append('SpecList[' + index + '].SpecSeq', item.SpecSeq);
                    formData.append('SpecList[' + index + '].SpecEnabled', 1);
                });
            }

            editProduct(formData, getToken())
                .then(res => {
                    if (res.data) {
                        history.push('/AdminGotonWeb/PCM');
                    }
                })
                .catch(err => {
                    console.log('err', err);
                })
                .finally(() => setloadico(false))
        }

    }

    const delProduct = () => {
        var yes = window.confirm('你確定刪除該商品嗎？');

        if (yes) {
            if (values.ProductID) {
                setloadico(true);
                deleteProduct(values, getToken())
                    .then(res => {
                        if (res.data) {
                            history.push('/AdminGotonWeb/PCM');
                        }
                    })
                    .catch(err => {
                        console.log('err', err);
                    })
                    .finally(() => setloadico(false))
            }
        }
    }

    const setDelete2 = () => {
        setOriFile2({ ...Orifile2, isDelete: !Orifile2.isDelete });
    }

    const setDelete3 = () => {
        setOriFile3({ ...Orifile3, isDelete: !Orifile3.isDelete });
    }

    const setDelete4 = () => {
        setOriFile4({ ...Orifile4, isDelete: !Orifile4.isDelete });
    }


    const oriImage1 = (Orifile1 != undefined && Orifile1.img != undefined && Orifile1.img !== '') ? (
        <div>
            <h1>原圖</h1>
            <img style={{
                display: 'block',
                width: '200px',
                height: '200px'
            }} src={`data:image/jpeg;base64,${Orifile1.img}`} loading="lazy" />
        </div>
    ) : (<></>);

    const oriImage2 = (Orifile2 != undefined && Orifile2.img != undefined && Orifile2.img !== '') ? (
        <div>
            <h1>原圖</h1>
            <img style={{
                display: !(Orifile2.isDelete)?'block':'none',
                width: '200px',
                height: '200px'
            }} src={`data:image/jpeg;base64,${Orifile2.img}`} loading="lazy" />
            <Button variant="contained" style={{ width: '100%', display: !Orifile2.isDelete ? 'block' : 'none' }} color="secondary" size="small" onClick={setDelete2}>
                刪除原圖
            </Button>
            <Button variant="contained" style={{ width: '200px', display: Orifile2.isDelete ? 'block' : 'none' }} color="primary" size="small" onClick={setDelete2} >
                還原刪除
            </Button>
        </div>
    ) : (<></>);

    const oriImage3 = (Orifile3 != undefined && Orifile3.img != undefined && Orifile3.img !== '') ? (
        <div>
            <h1>原圖</h1>
            <img style={{
                display: !(Orifile3.isDelete) ? 'block' : 'none',
                width: '200px',
                height: '200px'
            }} src={`data:image/jpeg;base64,${Orifile3.img}`} loading="lazy" />
            <Button variant="contained" style={{ width: '100%', display: !Orifile3.isDelete ? 'block' : 'none' }} color="secondary" size="small" onClick={setDelete3}>
                刪除原圖
            </Button>
            <Button variant="contained" style={{ width: '200px', display: Orifile3.isDelete ? 'block' : 'none' }} color="primary" size="small" onClick={setDelete3} >
                還原刪除
            </Button>
        </div>
    ) : (<></>);

    const oriImage4 = (Orifile4 != undefined && Orifile4.img != undefined && Orifile4.img !== '') ? (
        <div>
            <h1>原圖</h1>
            <img style={{
                display: !(Orifile4.isDelete) ? 'block' : 'none',
                width: '200px',
                height: '200px'
            }} src={`data:image/jpeg;base64,${Orifile4.img}`} loading="lazy" />
            <Button variant="contained" style={{ width: '100%', display: !Orifile4.isDelete ? 'block' : 'none' }} color="secondary" size="small" onClick={setDelete4}>
                刪除原圖
            </Button>
            <Button variant="contained" style={{ width: '200px', display: Orifile4.isDelete ? 'block' : 'none' }} color="primary" size="small" onClick={setDelete4} >
                還原刪除
            </Button>
        </div>
    ) : (<></>);

    return (
        <>
            <Backdrop open={loadico} className={classes.backdrop}>
                <CircularProgress color="secondary" />
            </Backdrop>
            <form className={classes.root} autoComplete="off" onSubmit={submit}>
                <h2>修改產品目錄</h2>
                <TextField
                    id="ProductID"
                    label="產品編號"
                    helperText="這裡是系統自動產生不用修改，只供辨示"
                    value={values.ProductID}
                    disabled
                />
                <div style={{ margin: '8px' }}>
                    <FormControl>
                        <FormHelperText style={{ fontSize: '16px', color: 'Black', fontWeight: 'bold' }}>選擇產品所屬分類的目錄：</FormHelperText>
                        <Select
                            value={values.ProductCategoryID}
                            onChange={handleProductCategoryIDChange}
                        >
                            <MenuItem value={0}>請選擇產品分類目錄</MenuItem>
                            {
                                ListData.map(
                                    (childItem, chiIndex) => {
                                        return (
                                            <MenuItem key={chiIndex} value={childItem.ProductCategoryID}>{childItem.ProductCategoryName}</MenuItem>
                                        )
                                    }
                                )
                            }

                        </Select>
                    </FormControl>
                </div>
                <TextField
                    id="ProductName"
                    label="產品名稱"
                    helperText="這裡輸入產品名稱例如（HBM-00）"
                    onChange={handleName}
                    value={values.ProductName}
                    required
                />
                <TextField
                    id="ProductSeq"
                    label="排序"
                    type="number"
                    helperText="產品顯示順序由小到大排序"
                    onChange={handleSeq}
                    value={values.ProductSeq}
                    required
                />
                <div style={{ margin: '8px' }}>
                    <FormControl>
                        <FormHelperText style={{ fontSize: '16px', color: 'Black', fontWeight: 'bold' }}>商品是否啟用：</FormHelperText>
                        <Select
                            value={values.ProductEnabled}
                            onChange={handleEnabledChange}
                        >
                            <MenuItem value={1}>啟用</MenuItem>
                            <MenuItem value={0}>停用</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{ margin: '8px' }}>
                    
                    <FormControl>
                        <FormHelperText style={{ fontSize: '16px', color: 'Black', fontWeight: 'bold' }}>商品類型：</FormHelperText>
                        <Select
                            value={values.IsCategoryType}
                            onChange={handleIsCategoryType}
                        >
                            <MenuItem value={0}>單項商品</MenuItem>
                            <MenuItem value={1}>多項商品展示</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{ margin: '8px' }}>
                    <FormControl>
                        <FormHelperText style={{ fontSize: '16px', color: 'Black', fontWeight: 'bold' }}>是否自動導入產品名細：</FormHelperText>
                        <Select
                            value={values.IsAutoRedirection}
                            onChange={handleIsAutoRedirection}
                        >
                            <MenuItem value={0}>否</MenuItem>
                            <MenuItem value={1}>是</MenuItem>
                        </Select>
                        <FormHelperText style={{ color: 'Red' }}>(*當相同目錄下有多項商品選擇「是」，取排序數最小者。</FormHelperText>
                    </FormControl>
                </div>
                <div style={{ marginLeft: '8px', marginTop: '46px' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Container style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                {oriImage1 }
                                <Dropzone title='修改後圖片一' getFile={setFile1} />
                                <h5 style={{ color: "red" }}>*如果不要修改變更圖片，請雙擊改後圖片將圖片刪除</h5>
                            </Container>
                        </Grid>
                        <Grid item xs={3}>
                            <Container style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                {oriImage2}
                                <div style={{ width: '100%', display: !Orifile2.isDelete ? 'block' : 'none' }}>
                                    <Dropzone title='修改後圖片二' getFile={setFile2} ImageData={Orifile2} />
                                    <h5 style={{ color: "red" }}>*如果不要修改變更圖片，請雙擊改後圖片將圖片刪除</h5>
                                </div>
                            </Container>
                        </Grid>
                        <Grid item xs={3}>
                            <Container style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                {oriImage3}
                                <div style={{ width: '100%', display: !Orifile3.isDelete ? 'block' : 'none' }}>
                                    <Dropzone title='修改後圖片三' getFile={setFile3} ImageData={Orifile3} />
                                    <h5 style={{ color: "red" }}>*如果不要修改變更圖片，請雙擊改後圖片將圖片刪除</h5>
                                </div>
                            </Container>
                        </Grid>
                        <Grid item xs={3}>
                            <Container style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                {oriImage4}
                                <div style={{ width: '100%', display: !Orifile4.isDelete ? 'block' : 'none' }}>
                                    <Dropzone title='修改後圖片四' getFile={setFile4} ImageData={Orifile4} />
                                    <h5 style={{color:"red"}}>*如果不要修改變更圖片，請雙擊改後圖片將圖片刪除</h5>
                                </div>
                            </Container>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ marginLeft: '8px', marginTop: '46px' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div style={{ width: '100%' }}>
                                <SpecTable getSpec={setSpec} specList={values.Spec}  />
                            </div>
                        </Grid>
                    </Grid>
                    <div style={{ margin: '8px' }}>
                        <FormControl>
                            <FormHelperText style={{ fontSize: '16px', color: 'Black', fontWeight: 'bold' }}>網頁是否顯示規格：</FormHelperText>
                            <Select
                                value={values.ShowSpec}
                                onChange={handleShowSpec}
                            >
                                <MenuItem value={1}>是</MenuItem>
                                <MenuItem value={0}>否</MenuItem>
                            </Select>
                            <FormHelperText style={{ color: 'Red' }}>(*當商品類型為「多項商品展示」時一律不顯示規格)</FormHelperText>
                        </FormControl>
                    </div>
                </div>

                <div style={{ marginLeft: '8px', marginTop: '46px' }}>

                    <Button variant="contained" color="primary" size="large" type="submit" startIcon={<SaveIcon />}>
                        儲存
                    </Button>

                    <Button style={{ marginLeft: '8px' }} variant="contained" color="secondary" size="large" onClick={delProduct } startIcon={<DeleteForeverIcon />}>
                        刪除
                    </Button>

                    <Link style={{ textDecoration: 'none', color: '#fff' }} to={"/AdminGotonWeb/PCM"}>
                        <Button variant="contained" color="secondary" size="large" style={{ marginLeft: '8px' }} startIcon={<CancelIcon />}>
                            取消
                        </Button>
                    </Link>

                </div>

            </form>


        </>

    );
}