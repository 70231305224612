import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ListIcon from '@material-ui/icons/List';
import PersonIcon from '@material-ui/icons/Person';
import StorageIcon from '@material-ui/icons/Storage';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';

import {
    Link
} from "react-router-dom";

export const mainListItems = (

    <div>
        <ListItem button component={Link} to={`/AdminGotonWeb/CM`}>
            <ListItemIcon>
                <ListIcon />
            </ListItemIcon>
            <ListItemText  primary="產品目錄管理"  />
        </ListItem>
        <ListItem button component={Link} to={`/AdminGotonWeb/PCM`}>
            <ListItemIcon>
                <ViewComfyIcon />
            </ListItemIcon>
            <ListItemText primary="產品管理" />
        </ListItem>
        <ListItem button component={Link} to={`/AdminGotonWeb/PM`}>
            <ListItemIcon>
                <StorageIcon />
            </ListItemIcon>
            <ListItemText primary="參數設定" />
        </ListItem>
        <ListItem button component={Link} to={`/AdminGotonWeb/PS`}>
            <ListItemIcon>
                <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="密碼設定" />
        </ListItem>
    </div>
);