import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

function Row(props) {
    const { row, childrow } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    return (
        <>
        <TableRow className={classes.root}>
            <TableCell>
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    {childrow.length>0?(open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />):""}
                </IconButton>
            </TableCell>
            <TableCell>
                {row.ProductCategoryName}
            </TableCell>
            <TableCell align="right">{row.ProductCategorySeq}</TableCell>
            <TableCell>
                {row.ProductCategoryEnabled?"是":"否"}
            </TableCell>
            <TableCell>
                    <Link color="inherit" to={`/AdminGotonWeb/CM/Edit/${row.ProductCategoryID}`}>
                            <IconButton color="primary" component="span">
                                <EditIcon />
                            </IconButton>
                        </Link>
            </TableCell>
        </TableRow>

        <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                 {childrow.map((cRow) => (
                     <TableRow key={cRow.ProductCategoryID}>
                         <TableCell>
                             <IconButton aria-label="expand row">
                                 <SubdirectoryArrowRightIcon />
                             </IconButton>
                         </TableCell>
                         <TableCell>
                             {cRow.ProductCategoryName}
                         </TableCell>
                         <TableCell align="right">{cRow.ProductCategorySeq}</TableCell>
                         <TableCell>
                             {cRow.ProductCategoryEnabled ? "是" : "否"}
                         </TableCell>
                         <TableCell>
                             <Link color="inherit" to={`/AdminGotonWeb/CM/Edit/${cRow.ProductCategoryID}`}>
                                 <IconButton color="primary" component="span">
                                     <EditIcon />
                                 </IconButton>
                             </Link>
                         </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
        </TableRow>
      </>
    )
}

export default function ProductCategoryGrid({ ProductCategoryData}) {
    return (
        <>
         <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>主目錄名稱</TableCell>
                        <TableCell align="right">排序</TableCell>
                        <TableCell>是否啟用</TableCell>
                        <TableCell>編輯</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            ProductCategoryData.filter(i => i.ProductCategoryParentID === 0).map((row) => (
                                <Row key={row.ProductCategoryID} row={row} childrow={ProductCategoryData.filter(i => i.ProductCategoryParentID === row.ProductCategoryID)} />
                            ))
                        }
                    </TableBody>
            </Table>
         </TableContainer>
        </>
    );
}