import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import {
    Link
} from "react-router-dom";

function handleClick(event) {
   
}

export default function GotonBreadcrumbs({ ProductCategory, CategoryName, ProductName, ProductCategoryParentId }) {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/" onClick={handleClick}>
                Home
      </Link>
            <Link color="inherit" to={"/Products/" + ProductCategory + "/" + ProductCategoryParentId} onClick={handleClick}>
                {CategoryName}
      </Link>
            <Typography color="textPrimary">{ProductName}</Typography>
        </Breadcrumbs>
    );
}