import React, { useState, useEffect } from 'react';
import Dropdown from './Dropdown';
import './Navbar.css';
import { getProductCategoryMenuList } from "../API/api.js";
import logo from '../images/logo.svg'
import { CaretDown, NavbarItem, NavbarLogo, MenuIcon, NavItem, NavLinks } from '../Style'
<script type="text/javascript" src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script>
function Navbar() {
    const [click, setClick] = useState(false);

    const [dropdown, setDropdown] = useState(false);

    const handleClick = () => setClick(!click);

    const closeMobileMenu = () => setClick(false);

    const [datas, getDatas] = useState([])


    const onMouseEnter = () => {
        if (window.innerWidth < 960) {
            setDropdown(false);
        } else {
            setDropdown(true);
        }
    };

    useEffect(() => {

        getMenu();

    }, []);
    //而當帶入空陣列時，則該 Effect 只會在首次 Render 時執行，如同 componentDidMount

    const getMenu = () => {

        getProductCategoryMenuList()
            .then(res => {
                getDatas(res.data);
            })
            .catch(err => {
                console.log(err);
                getDatas([]);
            })
    }

    const onMouseLeave = () => {
        if (window.innerWidth < 960) {
            setDropdown(false);
        } else {
            setDropdown(false);
        }
    };



    return (
        <>
            <NavbarItem>
                <img alt="companyLogo" className="companyTitleLogo"
                    src={logo}
                />
                <NavbarLogo to='/' onClick={closeMobileMenu}>
                   GOTON 
                </NavbarLogo>
                <MenuIcon onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </MenuIcon>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <NavItem>
                        <NavLinks to='/'  onClick={closeMobileMenu}>
                            Home
                        </NavLinks>
                    </NavItem>
                    <NavItem
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                    >
                        <NavLinks
                            to={datas.length>0?'/Products/' + datas[0].ProductCategoryID + '/' + datas[0].ProductCategoryParentID:'/'}
                            onClick={closeMobileMenu}
                        >
                            Products
                            <CaretDown className='fas fa-caret-down' />
                        </NavLinks>
                        {dropdown && <Dropdown menuDatas={datas} />}
                    </NavItem>
                    <NavItem>
                        <NavLinks to='/Company'  onClick={closeMobileMenu}>
                            Company
                        </NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='/Services' onClick={closeMobileMenu}>
                            Services
                        </NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='/Contact' onClick={closeMobileMenu}>
                            Contact
                        </NavLinks>
                    </NavItem>
                </ul>
                <div id="google_translate_element"></div>
            </NavbarItem>
        </>
    )
  

}

export default Navbar;