import React, { useState } from 'react';
import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {
    Link
} from "react-router-dom";

export default function ImagePaper(props) {

    const [selected, setSelected] = useState(0)

    const [imgselected, setimgSelected] = useState('75px')

    return (
        <>
            <Grid item width='250px' height='250px'
                onMouseOver={() => { setSelected(13);setimgSelected('150px') }}
                onMouseOut={() => { setSelected(0); setimgSelected('75px') }}
            >
                <Paper elevation={selected} >
                    <Link to={`/ProductDetail/${props.ProductID}/${props.Id}/${props.ParentId}`} style={{ textDecoration: 'none', fontColor: '' }} width='250px' height='250px'>
                        <img alt="ProductImage" width={imgselected} height={imgselected} src={`data:image/jpeg;base64,${props.ImageData}`} loading="lazy" className="ImagePaperCenter" />
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ marginTop: "15px", marginBottom: "15px", marginLeft: "5px", fontWeight: 'bold', fontSize: selected === 0 ? "16px" : "18px" }}>{props.ProductName}</div>
                        </div>
                    </Link>
                   
                </Paper>
               
            </Grid>
        </>

    );



}
