import React, { useEffect } from 'react';

import HOPE from './HOPE';
import ButtonBlock from './ButtonBlock';

import AOS from 'aos';
import './Pages.css';
import "aos/dist/aos.css"

export default function Company() {
    useEffect(() => {
        AOS.init({
            duration: 2000
        });
    }, []);

    const FirstWordStyle = {
        fontSize: '60px',
        fontWeight: '700',
        background: 'linear-gradient(to top,#F6416C, #FFF6B7)',
        WebkitBackgroundClip: "text", // Removed !important
        backgroundClip: "text", // Removed !important
        color: "transparent"
    }

    return (
        <>

            <div className='Company1'>
                <div className="Com">
                    <div>Company</div>
                    <p data-aos='fade-left'><span className="FirstWord" style={FirstWordStyle}>H</span><span className="OtherWord">onest</span></p>
                    <p data-aos='fade-right'><span className="FirstWord" style={FirstWordStyle}>O</span><span className="OtherWord">ne-Stop</span></p>
                    <p data-aos='fade-left'><span className="FirstWord" style={FirstWordStyle}>P</span><span className="OtherWord">rofession</span></p>
                    <p data-aos='fade-right'><span className="FirstWord" style={FirstWordStyle}>E</span><span className="OtherWord">nvironment</span></p>
                </div>
            </div>
            <div className='Company2'>
                <div className='Company2Container'>
                    <div className='C1' data-aos='zoom-in'>
                    </div>
                    <div className='C2' data-aos='fade-left'>
                        <div className='C2content'>
                            <div className="C2title">COMPANY</div>
                            <div className="content">
                                20 years is not a short time for a technology company. To GoTon, it is a wonderful process of growing. We met many challenges, even in the very difficult time, and we got through. With great hopes in minds, every GoTon’s members dedicate to the company and make it great.
                                Nowadays, GoTon is an excellent OEM/ODM manufacturer of 2-way radio audio accessories (radio accessory). We will keep going ahead with the HOPE.
                            </div>
                            <div className="content">
                                GoTon is a professional and reliable company. We welcome you to inquiry or share us your questions or opinions. We are happy to provide you the nice services for your business.
                             </div>
                        </div>
                      
                    </div>
                </div>
            </div>
            <HOPE />
            <ButtonBlock />
        </>
    );
}