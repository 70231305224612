import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

function Dropzone({ title, getFile }) {

    const thumb = {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
    };



    const img = {
        display: 'block',
        width: '200px',
        height: '200px'
    };

    const [files, setFiles] = useState([]);

    const { getRootProps, getInputProps, open } = useDropzone({
        accept: 'image/*',
        noClick: true,
        maxFiles: 1,
        noKeyboard: true,
        onDrop: acceptedFiles => {
            getFile(...acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const delectimg = () => {
        setFiles([]);
        getFile([]);
    }

    const thumbs = (
        files.map(file => (
            <div style={thumb} key={file.name}>
                <img
                    src={file.preview}
                    style={img}
                    onClick={delectimg}
                />
            </div>
        ))
    );

    const fileName = files.map(file => (
        <h4 key={file.path}>
            檔案名稱 {file.path} - {file.size} bytes
        </h4>
    ));

    useEffect(() => () => {

        files.forEach(file => {
             // Make sure to revoke the data uris to avoid memory leaks  這一行要加入否則暫存檔會一直在記憶體
            URL.revokeObjectURL(file.preview);
          
        });
    }, [files]);

    return (

        <div {...getRootProps({ className: 'dropzone' })}>

            <h1>{title}</h1>
            {thumbs}
            <input {...getInputProps()} />
            <Button variant="contained" size="small" startIcon={<CloudUploadIcon />} color="primary" component="span" onClick={open}>
                開啟檔案
            </Button>
            <aside>
                {fileName}
            </aside>
        </div>

    );
}

export default Dropzone;