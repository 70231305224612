import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Zmage from 'react-zmage'
import ButtonBlock from './ButtonBlock';
import ImagePaper from './ImagePaper';
import GotonBreadcrumbs from './GotonBreadcrumbs';
import ProductCategoryList from './ProductCategoryList';

import { getProductDetail, getRelatedProductList, getProductCategoryList } from "../API/api.js";
import './Pages.css';

export default function ProductDetail() {

    let { ProductId } = useParams()
    let { id } = useParams(1)
    let { parentId } = useParams(1)

    const [datas, setDatas] = useState({})

    const [RelatedDatas, setRelatedDatas] = useState([])

    const [ListData, setListData] = useState([])

    const getList = () => {

        getProductCategoryList()
            .then(res => {
                const valuesArray = res.data;

                setListData(valuesArray);

            })
            .catch(err => {
                console.log('err', err);
            })
    }

    const loadRelatedDatas = (ProductId) => {

        getRelatedProductList({ productId: ProductId })
            .then(res => {
                setRelatedDatas(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }
   
    //主圖
    const [img, setimg] = useState('')

    //選擇到的紅框
    const [imgIndex, setimgIndex] = useState(0)

    //所有圖
    const [allImgList, setAllImgList] = useState([])

    const paperStyle = {
        padding: 12,
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        backgroundColor: '#fff',
        marginTop: '20px',
        marginBottom: '20px'
    }


    const [spec, getspec] = useState([])

    const getDetail = (productId) => {

        getProductDetail(productId)
            .then(res => {

                setDatas(res.data);
            })
            .catch(err => {
                console.log('err', err);
            })
    }

    useEffect(() => {
        getDetail(ProductId);
        loadRelatedDatas(ProductId);
    }, [ProductId]);

    

    useEffect(() => {

        if (datas.ProductImageList !== undefined) {

            //console.log(datas, datas.ProductCategory);

            setimg(datas.ProductImageList[0].ProductImageData)

            const newImageList = [
                ...datas.ProductImageList
            ];

            setAllImgList(newImageList)
         
        }

    }, [datas.ProductImageList]);

    useEffect(() => {

        if (datas.ProductSpecList !== undefined) {
            getspec(datas.ProductSpecList)
        }

    }, [datas.ProductSpecList]);

 
    useEffect(() => {

        getList();

    }, []);


    if (datas.ProductName == undefined) {
        return <h1></h1>
    }

    let renderItems = (arr) => {
        if(arr){
            return (
                arr.map((item) => (
                    <li className="liSpec" key={item.Pkid}>
                        {item.Spec}
                    </li>
                ))
            );
        }
    }

    return (

        <>
            <div className="ProductDetail">
                <div className="div90">
                    <Grid container
                        direction="row"
                        justify="space-evenly"
                        alignItems="flex-start"
                        spacing={3}>
                        <Grid item xs={12}>
                            <GotonBreadcrumbs ProductCategory={datas.ProductCategory} ProductCategoryParentId={parentId} CategoryName={datas.ProductCategoryName} ProductName={datas.ProductName} />
                        </Grid>
                        <Grid item md={3} sm={12}>
                            {<ProductCategoryList ProductCategoryData={ListData} Id={id} ParentId={parentId} />}
                        </Grid>
                        <Grid item md={6} sm={12}>

                            <Grid container justify="center" spacing={3}>
                                <Grid item>
                                    <Grid container direction="column" justifyContent="center" alignItems="flex-start"
                                        style={{
                                            display: !(datas.IsCategoryType == 1) ? 'block' : 'none'
                                        }}
                                    >
                                        {allImgList.map((value, index) => (
                                            <Grid key={index} item>
                                                <Box sx={{
                                                    bgcolor: 'background.paper',
                                                    color: (imgIndex === index) ? 'secondary.main' : 'text.secondary',
                                                    m: 0.5,
                                                    border: 1,
                                                    width: '80px',
                                                    height: '80px'
                                                }}>

                                                    <Tooltip title={datas.ProductName} arrow placement="right">
                                                        <img style={{ height: '70px', width: '70px' }} alt={datas.ProductName} src={`data:image/jpeg;base64,${value.ProductImageData}`} onClick={() => { setimg(value.ProductImageData); setimgIndex(index) }} />
                                                    </Tooltip>

                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <div className="div100"
                                        style={{
                                            display: !(datas.IsCategoryType == 1) ? 'block' : 'none'
                                        }}
                                    >
                                        <Zmage style={{ height: 400, width: 400 }} alt={datas.ProductName} src={`data:image/jpeg;base64,${img}`}
                                        controller={{
                                            // 滚动时隐藏
                                            hideOnScroll: false
                                        }}
                                        />

                                    </div>
                                    <div className="div100"
                                        style={{
                                            display: (datas.IsCategoryType == 1) ? 'block' : 'none'
                                        }}
                                    >
                                        <h1>{datas.ProductName}</h1>
                                        {allImgList.map((value, index) => (
                                            <img style={{ width: 1200 }} alt={datas.ProductName} src={`data:image/jpeg;base64,${value.ProductImageData}`} />
                                        ))}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={3} sm={12}
                        style={{
                            display: (datas.ShowSpec == 1 && datas.IsCategoryType != 1) ? 'block' : 'none'
                        }}
                        >
                            <h1>{datas.ProductName}</h1>
                            <ul className="ulSpec">
                                {renderItems(spec)}
                            </ul>
                        </Grid>
                        <Grid item xs={12}
                            style={{
                                display: (datas.IsAutoRedirection == 1) ? 'none' : 'block'
                            }}
                        >
                            <Paper style={paperStyle}>
                                <h1 style={{ marginTop: "10px" }}>Related products</h1>
                                <Grid item lg={12} md={6} style={{ marginTop: "10px" }}>
                                    <Grid container
                                        direction="column"
                                        alignItems="center"
                                        justify="center"
                                        spacing={3}>
                                        <Grid item >
                                            <Grid container
                                                direction="row"
                                                justify="space-between"
                                                alignItems="flex-start"
                                                spacing={2}
                                            >
                                                {RelatedDatas.map((item) => (

                                                    <ImagePaper key={item.ProductID} ImageData={`${item.ProductImageData}`} ProductID={`${item.ProductID}`} ProductName={`${item.ProductName}`} Id={`${id}`} ParentId={`${parentId}`} />

                                                ))}
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>

                        </Grid>
                    </Grid>
                </div>
            </div>
         
           
         
        </>

    );
}