import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay, EffectFade  } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import './Pages.css';
import s1_1 from '../images/Home/JPG輪播1-1.jpg'
import s1_2 from '../images/Home/JPG輪播1-2.jpg'
import s1_3 from '../images/Home/JPG輪播1-3.jpg'
import s1_4 from '../images/Home/JPG輪播1-4.jpg'
import s1_5 from '../images/Home/JPG輪播1-5.jpg'
import s1_6 from '../images/Home/JPG輪播1-6.jpg'
import s1_7 from '../images/Home/JPG輪播1-7.jpg'
import s1_8 from '../images/Home/JPG輪播1-8.jpg'

function HomeImageSwiper1() {

    SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade ]);
   
    return (
        <div className='swiper-container1'>
            <Swiper
                effect={'fade'}
                spaceBetween={1}
                slidesPerView={1}
                autoplay={{ delay: 6000, disableOnInteraction: false, pauseOnMouseEnter: false }}
                navigation
                pagination={{ clickable: true }}
                touchMoveStopPropagation={false}
                loop={true}
                className='swiper1'
            >
                <SwiperSlide>
                    <img src={s1_1} className='SwiperSlideImg1' />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={s1_2} className='SwiperSlideImg1' />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={s1_3} className='SwiperSlideImg1' />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={s1_4} className='SwiperSlideImg1' />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={s1_5} className='SwiperSlideImg1' />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={s1_6} className='SwiperSlideImg1' />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={s1_7} className='SwiperSlideImg1' />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={s1_8} className='SwiperSlideImg1' />
                </SwiperSlide>
            </Swiper>
        </div>
      
    );
}

export default HomeImageSwiper1;