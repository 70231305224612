import React from 'react';
import HomeImageSwiper1 from './HomeImageSwiper1';
import HomeImageSwiper2 from './HomeImageSwiper2';
import ButtonBlock from './ButtonBlock';
import './Pages.css';


export default function Home() {


    return (
        <>
            <HomeImageSwiper1 />
            <div className='Home1'>
                
            </div>
            <HomeImageSwiper2 />
            <div className='Home2'>
             
            </div>

            <ButtonBlock />
        </>
    );
}