import React, { useState, useEffect, useRef } from 'react';
import logo from '../images/logo.svg'
import {  getContactSystemCode } from "../API/api.js";

function ButtonBlock() {
    const [ListData, setListData] = useState([]);

    const getContactData = () => {

        getContactSystemCode()
            .then(res => {
                const valuesArray = res.data;
                //console.log(valuesArray);
                setListData(valuesArray);

            })
            .catch(err => {
                console.log('err', err);
            })
    }

    useEffect(() => {
        getContactData();
    }, []);



    return (
        <div className="footer">
            <div className='button-container'>
                <div className='ButtonNote'>
                    <div>
                        <img className="companyLogo" alt="companyLogo"
                            src={logo}
                        />
                    </div>
                </div>
                <div className='ButtonInfo'>
                    <div>
                        <h4>CONTACT INFORMATION</h4>
                        <p width="100%">
                            <strong>Address：</strong>{(ListData.filter(item => item.Code == "Address").length > 0) ? ListData.filter(item => item.Code == "Address")[0].Value1 : ""}
                        </p>
                        <p width="100%">
                            <strong>Tel：</strong>{(ListData.filter(item => item.Code == "Tel").length > 0) ? ListData.filter(item => item.Code == "Tel")[0].Value1 : ""}  Fax：{(ListData.filter(item => item.Code == "Fax").length > 0) ? ListData.filter(item => item.Code == "Fax")[0].Value1 : ""}
                        </p>
                        <p width="100%">
                            <strong>E-mail：</strong>{(ListData.filter(item => item.Code == "Email1").length > 0) ? ListData.filter(item => item.Code == "Email1")[0].Value1 : ""}
                           /{(ListData.filter(item => item.Code == "Email2").length > 0) ? ListData.filter(item => item.Code == "Email2")[0].Value1 : ""}
                        </p>
                        <p width="100%">
                            <strong>Skype：</strong>{(ListData.filter(item => item.Code == "Skype").length > 0) ? ListData.filter(item => item.Code == "Skype")[0].Value1 : ""}
                        </p>
                    </div>
                </div>
            </div>
          

        </div>

    );
}

export default ButtonBlock;