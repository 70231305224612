import React from 'react';
import Navbar from './components/Navbar/Navbar';
import { BrowserRouter , Switch, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Products from './components/pages/Products';
import Company from './components/pages/Company';
import Services from './components/pages/Services';
import Contact from './components/pages/Contact';
import ProductDetail from './components/pages/ProductDetail';

function App() {
    return (
        <BrowserRouter>
            <Navbar />
            <Switch>
                <Route exact path='/'  component={Home} />

                {/*�p�G�S���[exact�A���a�Ѽƪ��|�]�|�i�h�o��Route�A���ܧⶶ�ǩ�U��*/}
                <Route exact path='/products' component={Products} />
                <Route path='/Products/:id/:parentId' component={Products} />
                <Route path='/ProductDetail/:ProductId/:id/:parentId' component={ProductDetail} />
                <Route path='/Company' component={Company} />
                <Route path='/Services' component={Services} />
                <Route path='/Contact' component={Contact} />
            </Switch>
        </BrowserRouter>

    );
}

export default App;
