import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Login from './components/pages/Login';
import AdminGotonWeb from './components/pages/AdminGotonWeb';

ReactDOM.render(
    
    <BrowserRouter>
        <Switch>
            <Route path='/Login' component={Login} />
            <Route path='/AdminGotonWeb' component={AdminGotonWeb} />
            <Route path='/' component={App} />
        </Switch>
    </BrowserRouter>

    , document.getElementById('root'));
