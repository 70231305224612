import React, { useState,useEffect,useRef }  from 'react';
import './Pages.css';
import AOS from 'aos';
import "aos/dist/aos.css"
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { sendMail, getContactSystemCode }  from "../API/api.js";

export default function Contact() {

    const textName = useRef();
    const textMail = useRef();
    const textSubject = useRef();
    const textMessage = useRef();

    const [loadico, getloadico] = useState(false)
    const [ListData, setListData] = useState([]);


    const submit = e => {
        e.preventDefault();

        const mailData = {
            Name: textName.current.value,
            Mail: textMail.current.value,
            Subject: textSubject.current.value,
            Message: textMessage.current.value
        }
        //console.log(mailData);
        getloadico(true);
        sendMail(mailData)
            .then(res => {
                if (res.data) {
                }
            })
            .catch(err => {
                console.log('err', err);
            })
            .finally(() => {
                getloadico(false);
                textName.current.value = "";
                textMail.current.value = "";
                textSubject.current.value = "";
                textMessage.current.value = "";
            })
      
    }

    const getContactData = () => {

        getContactSystemCode()
            .then(res => {
                const valuesArray = res.data;
                //console.log(valuesArray);
                setListData(valuesArray);

            })
            .catch(err => {
                console.log('err', err);
            })
    }

    useEffect(() => {
        getContactData();
    }, []);


    useEffect(() => {
        AOS.init({
            duration: 2000
        });
    }, []);

    return (
        <>
            <Backdrop open={loadico}>
                <CircularProgress color="secondary" />
            </Backdrop>

            <div className='Contact'>
                <h3 data-aos='zoom-in' className="ContactTitle">Contact Us</h3>
            </div>
            <div className='ContactContainer'>
                <div className='ContactNote' data-aos='fade-right'>
                    <form onSubmit={submit}>
                    <div>
                        <div className="title">Contact Us</div>
                    </div>

                        <div>
                            <label className="ContactLabel">Name</label>
                        <br />
                        <span>
                            <input type='text' name='your-name' ref={textName} defaultValue='' size='40'
                                    className='ContactInput'
                                aria-required='true' aria-invalid='false'  required/>
                        </span>
                    </div>


                        <div> <label className="ContactLabel">Your E-mail</label>
                        <br />
                        <span>
                            <input type='text' name='Your E-mail' ref={textMail} defaultValue='' size='40'
                                    className='ContactInput'
                                    aria-required='true' aria-invalid='false' required/>
                        </span>
                    </div>


                        <div><label className="ContactLabel">Subject</label>
                        <br />
                        <span>
                            <input type='text' name='Subject' ref={textSubject} defaultValue='' size='40'
                                    className='ContactInput'
                                    aria-required='true' aria-invalid='false' required/>
                        </span>
                    </div>


                        <div> <label className="ContactLabel">Message</label>
                        <br />
                        <span>
                            <input type='text' name='Message' ref={textMessage} defaultValue='' size='40'
                                    className='ContactInput'
                                    aria-required='true' aria-invalid='false' required/>
                        </span>
                    </div>
                    <div>
                        <br />
                        <p style={{ paddingBottom:"0px"}}>
                            <input type="submit" value="Submit" className="SubmitBtn" />
                        </p>
                        </div>
                    </form>
                </div>
                <div className='ContactInfo' data-aos='fade-left'>
                    <div>
                        <div className="ContactInfoTitle">Contact Information</div>
                    </div>
                    <div className='ContactAddr'>
                        <p width="100%">
                            <strong> Address：</strong>
                            <br />
                            {(ListData.filter(item => item.Code == "Address").length > 0) ? ListData.filter(item => item.Code == "Address")[0].Value1 : ""}
                        </p>
                        <p width="100%">
                            <strong>Tel：</strong>{(ListData.filter(item => item.Code == "Tel").length > 0) ? ListData.filter(item => item.Code == "Tel")[0].Value1 : ""}
                        </p>
                        <p width="100%">
                            <strong>Fax：</strong>{(ListData.filter(item => item.Code == "Fax").length > 0) ? ListData.filter(item => item.Code == "Fax")[0].Value1 : ""}
                        </p>
                        <p width="100%">
                            <strong>E-mail：</strong>
                            <br />
                            {(ListData.filter(item => item.Code == "Email1").length > 0) ? ListData.filter(item => item.Code == "Email1")[0].Value1 : ""}
                            <br />
                            {(ListData.filter(item => item.Code == "Email2").length > 0) ? ListData.filter(item => item.Code == "Email2")[0].Value1 : ""}
                        </p>
                        <p width="100%">
                            <strong>Skype：</strong>
                            {(ListData.filter(item => item.Code == "Skype").length > 0) ? ListData.filter(item => item.Code == "Skype")[0].Value1 : ""}
                        </p>
                    </div>
                    
                </div>
            </div>


        </>

    );
}