import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";

import { makeStyles, withStyles } from '@material-ui/core/styles';

import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support
import PropTypes from 'prop-types';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import ExpandLessIcon from '@material-ui/icons/ExpandLess';
//import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';


function MinusSquare(props) {
    return (
        <FiberManualRecordIcon style={{ width: 14, height: 14 }} />
    );
}

function PlusSquare(props) {
    return (
        <FiberManualRecordIcon style={{ width: 14, height: 14 }} />
    );
}

//function CloseSquare(props) {
//    return (
//        <FiberManualRecordIcon style={{ width: 14, height: 14 }} />
//    );
//}

function TransitionComponent(props) {
    return (
        <animated.div >
            <Collapse {...props} />
        </animated.div>
    );
}

TransitionComponent.propTypes = {
    /**
     * Show the component; triggers the enter or exit states
     */
    in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18
    },
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles({
    root: {
        height: 264,
        flexGrow: 1,
        maxWidth: 400,
        fontFamily: "Montserrat,arial"
    },
});




export default function ProductCategoryList({ ProductCategoryData, Id, ParentId }) {
    const classes = useStyles();
    const history = useHistory();

    const [expand, setExpand] = useState([String(ParentId)]);
    

    useEffect(() => {
        var items = ProductCategoryData.filter(i => i.ProductCategoryID == Id && i.ProductCategoryParentID == ParentId);

        if (items != undefined && items.length > 0) {
            var childItem = items[0];
            if (childItem.isAutoRedirectionProductID != "") {
                history.push(`/ProductDetail/${childItem.isAutoRedirectionProductID}/${childItem.ProductCategoryID}/${childItem.ProductCategoryParentID}`);
            }
        }

        setExpand([String(ParentId)]);
    }, [ParentId]);
   

    
    return (
       
        <TreeView
            className={classes.root}
            defaultExpanded={[]}

            expanded={expand}

            selected={String(Id)}
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}
            defaultEndIcon={<PlusSquare />}

            onNodeToggle={(nodeId, isExpanded) => {
               
                if (isExpanded[0] !== undefined) {
                    setExpand([String(isExpanded[0])]) 
                }
               
            }}
        >
            {

                ProductCategoryData.map((item, index) => {
                    if (item.ProductCategoryParentID === 0) {

                        return (
                            <Link key={index} to={(ProductCategoryData.filter(i => i.ProductCategoryParentID === item.ProductCategoryID).length > 0) ? (`#`) : (`/Products/${item.ProductCategoryID}/${item.ProductCategoryParentID}`)} style={{ textDecoration: 'none' }}>
                                <StyledTreeItem nodeId={String(item.ProductCategoryID)} label={item.ProductCategoryName} >
                                    {
                                        ProductCategoryData.filter(i => i.ProductCategoryParentID === item.ProductCategoryID).map(
                                            (childItem, chiIndex) => {
                                                if (childItem.isAutoRedirectionProductID != "") {
                                                    return (

                                                        <Link key={chiIndex} to={`/ProductDetail/${childItem.isAutoRedirectionProductID}/${childItem.ProductCategoryID}/${childItem.ProductCategoryParentID}`} style={{ textDecoration: 'none' }}>
                                                            <StyledTreeItem nodeId={String(childItem.ProductCategoryID)} label={childItem.ProductCategoryName} />
                                                        </Link>

                                                    )
                                                } else {
                                                    return (

                                                        <Link key={chiIndex} to={`/Products/${childItem.ProductCategoryID}/${childItem.ProductCategoryParentID}`} style={{ textDecoration: 'none' }}>
                                                            <StyledTreeItem nodeId={String(childItem.ProductCategoryID)} label={childItem.ProductCategoryName} />
                                                        </Link>

                                                    )
                                                }
                                               
                                            }
                                        )

                                    }
                                </StyledTreeItem>
                            </Link>

                        )
                    } else {
                        return "";
                    }

                })
            }
        </TreeView>
    );
}
