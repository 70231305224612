import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Link } from 'react-router-dom';
import { getHomeImageSwiper2Link } from "../API/api.js";

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import './Pages.css';

import s2_1 from '../images/Home/JPG輪播2-1.jpg'
import s2_2 from '../images/Home/JPG輪播2-2.jpg'
import s2_3 from '../images/Home/JPG輪播2-3.jpg'
import s2_4 from '../images/Home/JPG輪播2-4.jpg'
import s2_5 from '../images/Home/JPG輪播2-5.jpg'
import s2_6 from '../images/Home/JPG輪播2-6.jpg'
import s2_7 from '../images/Home/JPG輪播2-7.jpg'
import s2_8 from '../images/Home/JPG輪播2-8.jpg'

function HomeImageSwiper2() {

    SwiperCore.use([Navigation, Pagination, Autoplay]);
    const [ListData, setListData] = useState([]);

    const getLinkData = () => {

        getHomeImageSwiper2Link()
            .then(res => {
                const valuesArray = res.data;
                //console.log(valuesArray);
                setListData(valuesArray);

            })
            .catch(err => {
                console.log('err', err);
            })
    }

    useEffect(() => {
        getLinkData();
    }, []);


    return (
        <div className='swiper-container2'>
            <Swiper
                spaceBetween={1}
                slidesPerView={1}
                autoplay={{ delay: 4000, disableOnInteraction: false, pauseOnMouseEnter: false }}
                navigation
                pagination={{ clickable: true }}
                touchMoveStopPropagation={false}
                loop={true}
                className='swiper2'
            >
                <SwiperSlide>
                    <Link style={{ textDecoration: 'none', color: '#fff'}} to={(ListData.filter(item => item.Code == "Swiper2Link1").length > 0) ? ListData.filter(item => item.Code == "Swiper2Link1")[0].Value1 : "#"}>
                        <img src={s2_1} className='SwiperSlideImg2' />
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link style={{ textDecoration: 'none', color: '#fff' }} to={(ListData.filter(item => item.Code == "Swiper2Link2").length > 0) ? ListData.filter(item => item.Code == "Swiper2Link2")[0].Value1 : "#"}>
                        <img src={s2_2} className='SwiperSlideImg2' />
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link style={{ textDecoration: 'none', color: '#fff' }} to={(ListData.filter(item => item.Code == "Swiper2Link3").length > 0) ? ListData.filter(item => item.Code == "Swiper2Link3")[0].Value1 : "#"}>
                        <img src={s2_3} className='SwiperSlideImg2' />
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link style={{ textDecoration: 'none', color: '#fff' }} to={(ListData.filter(item => item.Code == "Swiper2Link4").length > 0) ? ListData.filter(item => item.Code == "Swiper2Link4")[0].Value1 : "#"}>
                        <img src={s2_4} className='SwiperSlideImg2' />
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link style={{ textDecoration: 'none', color: '#fff' }} to={(ListData.filter(item => item.Code == "Swiper2Link5").length > 0) ? ListData.filter(item => item.Code == "Swiper2Link5")[0].Value1 : "#"}>
                        <img src={s2_5} className='SwiperSlideImg2' />
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link style={{ textDecoration: 'none', color: '#fff' }} to={(ListData.filter(item => item.Code == "Swiper2Link6").length > 0) ? ListData.filter(item => item.Code == "Swiper2Link6")[0].Value1 : "#"}>
                        <img src={s2_6} className='SwiperSlideImg2' />
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link style={{ textDecoration: 'none', color: '#fff' }} to={(ListData.filter(item => item.Code == "Swiper2Link7").length > 0) ? ListData.filter(item => item.Code == "Swiper2Link7")[0].Value1 : "#"}>
                        <img src={s2_7} className='SwiperSlideImg2' />
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link style={{ textDecoration: 'none', color: '#fff' }} to={(ListData.filter(item => item.Code == "Swiper2Link8").length > 0) ? ListData.filter(item => item.Code == "Swiper2Link8")[0].Value1 : "#"}>
                        <img src={s2_8} className='SwiperSlideImg2' />
                    </Link>
                </SwiperSlide>
            </Swiper>
        </div>

    );
}

export default HomeImageSwiper2;