import React, { useState } from 'react';
import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {
    Link
} from "react-router-dom";

export default function AdminImagePaper(props) {
     

    const [imgselected, setimgSelected] = useState('75px')

    return (
        <>
            <Grid item width='150px' height='150px'>
                <Paper elevation={0} >
                    <Link to={`/AdminGotonWeb/PCM/Edit/${props.ProductID}`} style={{ textDecoration: 'none', fontColor: '' }} width='150px' height='150px'>
                        <img alt="ProductImage" width={imgselected} height={imgselected} src={`data:image/jpeg;base64,${props.ImageData}`} loading="lazy" className="ImagePaperCenter" />
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ marginTop: "5px", marginBottom: "5px", marginLeft: "2px", fontWeight: 'bold', fontSize: "12px" }}>{props.ProductName} {props.ProductEnabled == 0 ? <div style={{ color: 'red' }}>(已停用)</div> : <div style={{ color: '#7CFC00' }}>(已啟用)</div>}</div>
                        </div>
                    </Link>

                </Paper>

            </Grid>
        </>

    );



}
