import React from 'react';
import './Pages.css';

function HOPE() {

    return (
        <>
            <div className='HO'>
                <div className='H'>
                    <div className="slogan">
                        <div className="sloganTitle">
                            Honest
                       </div>
                        <hr className='sloganHr'/>
                        <div className="sloganCotent">
                            Honesty is the foundation of relationships between Goton and customers to create the Win-Win business. Many customers have cooperated with us for 10 years or more. To GoTon, customers are our friends and partners, from whom we learn to make us more reliable and helpful to them. GoTon is always a company of integrity
                       </div>
                    </div>
                </div>
                <div className='O'>
                    <div className="slogan">
                        <div className="sloganTitle">
                            One-stop service
                       </div>
                        <hr className='sloganHr'/>
                        <div className="sloganCotent">
                            One-stop service provides our customers an efficient and money-saving purchasing/developing platform. GoTon have numerous molds and tools for products compatible with brand transceivers. We also can make/design adaptations/modifications for customers to get more flexible products for markets
                       </div>
                    </div>

                </div>
            </div>
            <div className='PE'>
                <div className='P'>
                    <div className="slogan">
                        <div className="sloganTitle">
                            Profession
                       </div>
                        <hr className='sloganHr' />
                        <div className="sloganCotent">
                            Professional engineering team is one of GoTon’s core abilities. In the past years, we got a lot of experiences from many failures. With keeping learning and improving, our techniques and know how let us to provide more stable quality products. GoTon is your best choice in 2-way radio audio accessories business
                         </div>
                    </div>

                </div>
                <div className='E'>
                    <div className="slogan">
                        <div className="sloganTitle">
                            Environment friendly
                       </div>
                        <hr className='sloganHr'/>
                        <div className="sloganCotent">
                            Environment friendly is one of our company policies. All materials in productions are RoHS compliant. Our R&D team process every projects/products on the basis of meeting essential requirements of CE marking. GoTon also creates nice/friendly working area for staffs to enhance their efficiency and accuracy to ensure quality results/products
                       </div>
                    </div>

                </div>
            </div>
        </>
     
    );
}

export default HOPE;