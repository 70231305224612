import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getContactSystemCode, setSystemCode, getHomeImageSwiper2Link } from "../API/api.js"

import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { getToken, IsLogin } from './auth';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '600px',
        },
        formControl: {
            margin: theme.spacing(1),

            minWidth: '600px',
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
}));


export default function PM() {

    const classes = useStyles();

    const [CurrAddress, setCurrAddress] = useState('');
    const [CurrSkype, setCurrSkype] = useState('');
    const [CurrTel, setCurrTel] = useState('');
    const [CurrFax, setCurrFax] = useState('');
    const [CurrEmail1, setCurrEmail1] = useState('');
    const [CurrEmail2, setCurrEmail2] = useState('');

    const [CurrSwiper2Link1, setCurrSwiper2Link1] = useState('');
    const [CurrSwiper2Link2, setCurrSwiper2Link2] = useState('');
    const [CurrSwiper2Link3, setCurrSwiper2Link3] = useState('');
    const [CurrSwiper2Link4, setCurrSwiper2Link4] = useState('');
    const [CurrSwiper2Link5, setCurrSwiper2Link5] = useState('');
    const [CurrSwiper2Link6, setCurrSwiper2Link6] = useState('');
    const [CurrSwiper2Link7, setCurrSwiper2Link7] = useState('');
    const [CurrSwiper2Link8, setCurrSwiper2Link8] = useState('');

    const [Address, setAddress] = useState('');
    const [Skype, setSkype] = useState('');
    const [Tel, setTel] = useState('');
    const [Fax, setFax] = useState('');
    const [Email1, setEmail1] = useState('');
    const [Email2, setEmail2] = useState('');

    const [Swiper2Link1, setSwiper2Link1] = useState('');
    const [Swiper2Link2, setSwiper2Link2] = useState('');
    const [Swiper2Link3, setSwiper2Link3] = useState('');
    const [Swiper2Link4, setSwiper2Link4] = useState('');
    const [Swiper2Link5, setSwiper2Link5] = useState('');
    const [Swiper2Link6, setSwiper2Link6] = useState('');
    const [Swiper2Link7, setSwiper2Link7] = useState('');
    const [Swiper2Link8, setSwiper2Link8] = useState('');


    const [loadico, getloadico] = useState(false)

    const history = useHistory();

    const getList = () => {
        getContactSystemCode()
            .then(res => {
                let ListData = res.data;
                if (ListData != undefined && ListData.length > 0) {
                    setCurrAddress((ListData.filter(item => item.Code == "Address").length > 0) ? ListData.filter(item => item.Code == "Address")[0].Value1 : "");
                    setCurrSkype((ListData.filter(item => item.Code == "Skype").length > 0) ? ListData.filter(item => item.Code == "Skype")[0].Value1 : "");
                    setCurrTel((ListData.filter(item => item.Code == "Tel").length > 0) ? ListData.filter(item => item.Code == "Tel")[0].Value1 : "");
                    setCurrFax((ListData.filter(item => item.Code == "Fax").length > 0) ? ListData.filter(item => item.Code == "Fax")[0].Value1 : "");
                    setCurrEmail1((ListData.filter(item => item.Code == "Email1").length > 0) ? ListData.filter(item => item.Code == "Email1")[0].Value1 : "");
                    setCurrEmail2((ListData.filter(item => item.Code == "Email2").length > 0) ? ListData.filter(item => item.Code == "Email2")[0].Value1 : "");
                }
            })
            .catch(err => {
                console.log('err', err);
            })

        getHomeImageSwiper2Link()
            .then(res => {
                let ListData = res.data;
                if (ListData != undefined && ListData.length > 0) {
                    setCurrSwiper2Link1((ListData.filter(item => item.Code == "Swiper2Link1").length > 0) ? ListData.filter(item => item.Code == "Swiper2Link1")[0].Value1 : "");
                    setCurrSwiper2Link2((ListData.filter(item => item.Code == "Swiper2Link2").length > 0) ? ListData.filter(item => item.Code == "Swiper2Link2")[0].Value1 : "");
                    setCurrSwiper2Link3((ListData.filter(item => item.Code == "Swiper2Link3").length > 0) ? ListData.filter(item => item.Code == "Swiper2Link3")[0].Value1 : "");
                    setCurrSwiper2Link4((ListData.filter(item => item.Code == "Swiper2Link4").length > 0) ? ListData.filter(item => item.Code == "Swiper2Link4")[0].Value1 : "");
                    setCurrSwiper2Link5((ListData.filter(item => item.Code == "Swiper2Link5").length > 0) ? ListData.filter(item => item.Code == "Swiper2Link5")[0].Value1 : "");
                    setCurrSwiper2Link6((ListData.filter(item => item.Code == "Swiper2Link6").length > 0) ? ListData.filter(item => item.Code == "Swiper2Link6")[0].Value1 : "");
                    setCurrSwiper2Link7((ListData.filter(item => item.Code == "Swiper2Link7").length > 0) ? ListData.filter(item => item.Code == "Swiper2Link7")[0].Value1 : "");
                    setCurrSwiper2Link8((ListData.filter(item => item.Code == "Swiper2Link8").length > 0) ? ListData.filter(item => item.Code == "Swiper2Link8")[0].Value1 : "");
                }
            })
            .catch(err => {
                console.log('err', err);
            })

    }

    useEffect(() => {

        try {
            getloadico(true);
            getList();

        } catch (error) {
            console.error(error);

        } finally {
            getloadico(false)
        }


    }, []);

    const submit = e => {

        if (!IsLogin()) {
            alert('登入時間過期請重新登入');
            return;
        }

        e.preventDefault();

        const userData = {
            Address: Address.trim(),
            Skype: Skype.trim(),
            Tel: Tel.trim(),
            Fax: Fax.trim(),
            Email1: Email1.trim(),
            Email2: Email2.trim(),
            Swiper2Link1: Swiper2Link1.trim(),
            Swiper2Link2: Swiper2Link2.trim(),
            Swiper2Link3: Swiper2Link3.trim(),
            Swiper2Link4: Swiper2Link4.trim(),
            Swiper2Link5: Swiper2Link5.trim(),
            Swiper2Link6: Swiper2Link6.trim(),
            Swiper2Link7: Swiper2Link7.trim(),
            Swiper2Link8: Swiper2Link8.trim()
        }
        getloadico(true);

        setSystemCode(userData, getToken())
            .then(res => {
                if (res.data) {
                    alert('公司資訊更新成功');
                    getList();
                }
            })
            .catch(err => {
                alert('公司資訊更新失敗');
                console.log('err', err);
            })
            .finally(() => {
                getloadico(false);
                setAddress("");
                setSkype("");
                setTel("");
                setFax("");
                setEmail1("");
                setEmail2("");
                setSwiper2Link1("");
                setSwiper2Link2("");
                setSwiper2Link3("");
                setSwiper2Link4("");
                setSwiper2Link5("");
                setSwiper2Link6("");
                setSwiper2Link7("");
                setSwiper2Link8("");

            })

    }


    return (
        <>
            <Backdrop open={loadico} className={classes.backdrop}>
                <CircularProgress color="secondary" />
            </Backdrop>
            <form className={classes.root} autoComplete="off" onSubmit={submit}>
                <h2>修改Contact公司資訊</h2>
              
                <div style={{ margin: '8px' }}>
                    <h5>Office Address：{CurrAddress}</h5>
                    <TextField
                        id="Address"
                        label="修改後的Address"
                        onChange={event => setAddress(event.target.value)}
                        value={Address}

                    />
                </div>

                <div style={{ margin: '8px' }}>
                    <h5>Skype：{CurrSkype}</h5>
                    <TextField
                        id="Skype"
                        label="修改後的Skype"
                        onChange={event => setSkype(event.target.value)}
                        value={Skype}

                    />
                </div>

                <div style={{ margin: '8px' }}>
                    <h5>Tel：{CurrTel}</h5>
                    <TextField
                        id="Tel"
                        label="修改後的Tel"
                        onChange={event => setTel(event.target.value)}
                        value={Tel}

                    />
                </div>

                <div style={{ margin: '8px' }}>
                    <h5>Fax：{CurrFax}</h5>
                    <TextField
                        id="Fax"
                        label="修改後的Fax"
                        onChange={event => setFax(event.target.value)}
                        value={Fax}

                    />
                </div>

                <div style={{ margin: '8px' }}>
                    <h5>Email1：{CurrEmail1}</h5>
                    <TextField
                        id="Email1"
                        label="修改後的Email1"
                        onChange={event => setEmail1(event.target.value)}
                        value={Email1}

                    />
                </div>

                <div style={{ margin: '8px' }}>
                    <h5>Email2：{CurrEmail2}</h5>
                    <TextField
                        id="Email2"
                        label="修改後的Email2"
                        onChange={event => setEmail2(event.target.value)}
                        value={Email2}

                    />
                </div>

              
                <h2>修改HOME頁第二層輪播超連結URL</h2>
                <h3 style={{ color: "red" }}>請輸入https://goton.com.tw之後的站內連結。開頭必須包括「/」，例如「/Products/2/1」</h3>
                <div style={{ margin: '8px' }}>
                    <h5>第一張圖片連結：{CurrSwiper2Link1}</h5>
                    <TextField
                        id="Link1"
                        label="修改後的第一張圖片連結"
                        onChange={event => setSwiper2Link1(event.target.value)}
                        value={Swiper2Link1}

                    />
                </div>
                <div style={{ margin: '8px' }}>
                    <h5>第二張圖片連結：{CurrSwiper2Link2}</h5>
                    <TextField
                        id="Link2"
                        label="修改後的第二張圖片連結"
                        onChange={event => setSwiper2Link2(event.target.value)}
                        value={Swiper2Link2}

                    />
                </div>
                <div style={{ margin: '8px' }}>
                    <h5>第三張圖片連結：{CurrSwiper2Link3}</h5>
                    <TextField
                        id="Link3"
                        label="修改後的第三張圖片連結"
                        onChange={event => setSwiper2Link3(event.target.value)}
                        value={Swiper2Link3}

                    />
                </div>
                <div style={{ margin: '8px' }}>
                    <h5>第四張圖片連結：{CurrSwiper2Link4}</h5>
                    <TextField
                        id="Link4"
                        label="修改後的第四張圖片連結"
                        onChange={event => setSwiper2Link4(event.target.value)}
                        value={Swiper2Link4}

                    />
                </div>
                <div style={{ margin: '8px' }}>
                    <h5>第五張圖片連結：{CurrSwiper2Link5}</h5>
                    <TextField
                        id="Link5"
                        label="修改後的第五張圖片連結"
                        onChange={event => setSwiper2Link5(event.target.value)}
                        value={Swiper2Link5}

                    />
                </div>
                <div style={{ margin: '8px' }}>
                    <h5>第六張圖片連結：{CurrSwiper2Link6}</h5>
                    <TextField
                        id="Link6"
                        label="修改後的第六張圖片連結"
                        onChange={event => setSwiper2Link6(event.target.value)}
                        value={Swiper2Link6}

                    />
                </div>
                <div style={{ margin: '8px' }}>
                    <h5>第七張圖片連結：{CurrSwiper2Link7}</h5>
                    <TextField
                        id="Link7"
                        label="修改後的第七張圖片連結"
                        onChange={event => setSwiper2Link7(event.target.value)}
                        value={Swiper2Link7}

                    />
                </div>
                <div style={{ margin: '8px' }}>
                    <h5>第八張圖片連結：{CurrSwiper2Link8}</h5>
                    <TextField
                        id="Link8"
                        label="修改後的第八張圖片連結"
                        onChange={event => setSwiper2Link8(event.target.value)}
                        value={Swiper2Link8}

                    />
                </div>
             
                <div style={{ marginLeft: '8px', marginTop: '46px' }}>

                    <Button variant="contained" color="primary" type="submit" size="large" startIcon={<SaveIcon />}>
                        儲存
                    </Button>


                </div>
            </form>
        </>

    );
}