import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { getProductCategoryEndPointList, addProduct, checkProductExist } from "../../API/api.js";
import { getToken, IsLogin } from '../auth';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Dropzone from './Dropzone';
import SpecTable from './SpecTable';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '600px',
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: '600px',
        }
    },
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
}));

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #fff;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

export default function Add() {

    const classes = useStyles();

    let history = useHistory();

    const [values, setValues] = useState({
        ProductCategoryID: 0,
        ProductName: '',
        ProductSeq: 0,
        ProductEnabled: 1,
        IsCategoryType: 0,
        IsAutoRedirection: 0,
        ShowSpec: 1,
        File1: '',
        File2: '',
        File3: '',
        File4: '',
        Spec: []
    });

    const [file1, setFile1] = useState([]);
    const [file2, setFile2] = useState([]);
    const [file3, setFile3] = useState([]);
    const [file4, setFile4] = useState([]);
    const [spec, setSpec] = useState([]);

    useEffect(() => {
        //console.log("ok1", file1);
        setValues({ ...values, File1: file1 });
    }, [file1]);

    useEffect(() => {

        //console.log("ok2", file2);
        setValues({ ...values, File2: file2 });
    }, [file2]);

    useEffect(() => {
        //console.log("ok3", file3);
        setValues({ ...values, File3: file3 });
    }, [file3]);

    useEffect(() => {
        //console.log("ok4", file4);
        setValues({ ...values, File4: file4 });
    }, [file4]);

    useEffect(() => {
        //console.log("spec", spec);
        setValues({ ...values, Spec: spec });
    }, [spec]);

    const handleProductCategoryIDChange = (event) => {
        setValues({ ...values, ProductCategoryID: event.target.value });
    };

    const handleName = event => {
        setValues({ ...values, ProductName: event.target.value });
    };

    const handleSeq = event => {
        setValues({ ...values, ProductSeq: event.target.value });
    };

    const handleEnabledChange = (event) => {
        setValues({ ...values, ProductEnabled: event.target.value });
    };

    const handleIsCategoryType = (event) => {
        setValues({ ...values, IsCategoryType: event.target.value });
    };

    const handleIsAutoRedirection = (event) => {
        setValues({ ...values, IsAutoRedirection: event.target.value });
    };

    const handleShowSpec = (event) => {
        setValues({ ...values, ShowSpec: event.target.value });
    };

    const [ListData, getListData] = useState([])

    const [loadico, getloadico] = useState(false)

    const getList = () => {

        getProductCategoryEndPointList()
            .then(res => {
                const valuesArray = res.data;

                getListData(valuesArray);

            })
            .catch(err => {
                console.log('err', err);
            })
    }

    useEffect(() => {

        getList();

    }, []);


    const submit = e => {

        if (!IsLogin()) {
            alert('登入時間過期請重新登入');
            return;
        }

        e.preventDefault();

        if (values.ProductCategoryID === 0) {
            alert('請選擇產品分類目錄');
            return;
        }

        checkProductExist(values.ProductName)
            .then(res => {
                if (res.data) {
                    alert('商品名稱已存在');
                } else {

                    if (values.ProductName.length > 0 && values.ProductID !== 0) {
                        getloadico(true);

                        const formData = new FormData();
                        formData.append('ProductCategoryID', values.ProductCategoryID);
                        formData.append('ProductName', values.ProductName);
                        formData.append('ProductSeq', values.ProductSeq);
                        formData.append('ProductEnabled', values.ProductEnabled);
                        formData.append('IsCategoryType', values.IsCategoryType);
                        formData.append('IsAutoRedirection', values.IsAutoRedirection);
                        formData.append('ShowSpec', values.ShowSpec);
                        formData.append('File1', values.File1);
                        formData.append('File2', values.File2);
                        formData.append('File3', values.File3);
                        formData.append('File4', values.File4);

                        if (values.Spec != undefined && values.Spec.length > 0 && values.Spec[0] != undefined) {

                            values.Spec[0].forEach((item, index) => {
                                formData.append('SpecList[' + index + '].Pkid', item.UUID);
                                formData.append('SpecList[' + index + '].Spec', item.Spec);
                                formData.append('SpecList[' + index + '].SpecSeq', item.SpecSeq);
                                formData.append('SpecList[' + index + '].SpecEnabled', 1);
                            });
                        }


                        addProduct(formData, getToken())
                            .then(res => {
                                if (res.data) {
                                    history.push('/AdminGotonWeb/PCM');
                                }
                            })
                            .catch(err => {
                                console.log('err', err);
                            })
                            .finally(() => getloadico(false))
                    }

                }
            })
            .catch(err => {
                getloadico(false)
                console.log('err', err);
            })


    }

    return (
        <>
            <Backdrop open={loadico} className={classes.backdrop}>
                <CircularProgress color="secondary" />
            </Backdrop>
            <form className={classes.root} autoComplete="off" onSubmit={submit}>
                <h2>新增產品</h2>
                <div style={{ margin: '8px' }}>
                    <FormControl>
                        <FormHelperText style={{ fontSize: '16px', color: 'Black', fontWeight: 'bold' }}>選擇產品所屬分類的目錄：</FormHelperText>
                        <Select
                            value={values.ProductCategoryID}
                            onChange={handleProductCategoryIDChange}
                            required
                        >
                            <MenuItem value={0}>請選擇產品分類目錄</MenuItem>
                            {
                                ListData.map(
                                    (childItem, chiIndex) => {
                                        return (
                                            <MenuItem key={chiIndex} value={childItem.ProductCategoryID}>{childItem.ProductCategoryName}</MenuItem>
                                        )
                                    }
                                )
                            }

                        </Select>
                    </FormControl>
                </div>
                <TextField
                    id="ProductName"
                    label="產品名稱"
                    helperText="這裡輸入產品名稱例如（HBM-00）"
                    onChange={handleName}
                    value={values.ProductName}
                    required
                />
                <TextField
                    id="ProductSeq"
                    label="排序"
                    type="number"
                    helperText="產品顯示順序由小到大排序"
                    onChange={handleSeq}
                    value={values.ProductSeq}
                    required
                />
                <div style={{ margin: '8px' }}>
                    <FormHelperText style={{ fontSize: '16px', color: 'Black', fontWeight: 'bold' }}>商品是否啟用：</FormHelperText>
                    <FormControl>

                        <Select
                            value={values.ProductEnabled}
                            onChange={handleEnabledChange}
                        >
                            <MenuItem value={1}>啟用</MenuItem>
                            <MenuItem value={0}>停用</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{ margin: '8px' }}>
                    <FormControl>
                        <FormHelperText style={{ fontSize: '16px', color: 'Black', fontWeight: 'bold' }}>商品類型：</FormHelperText>
                        <Select
                            value={values.IsCategoryType}
                            onChange={handleIsCategoryType}
                        >
                            <MenuItem value={0}>單項商品</MenuItem>
                            <MenuItem value={1}>多項商品展示</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{ margin: '8px' }}>
                    <FormControl>
                        <FormHelperText style={{ fontSize: '16px', color: 'Black', fontWeight: 'bold' }}>是否自動導入產品名細：</FormHelperText>
                        <Select
                            value={values.IsAutoRedirection}
                            onChange={handleIsAutoRedirection}
                        >
                            <MenuItem value={0}>否</MenuItem>
                            <MenuItem value={1}>是</MenuItem>
                        </Select>
                        <FormHelperText style={{ color: 'Red' }}>(*當相同目錄下有多項商品選擇「是」，取排序數最小者。</FormHelperText>
                    </FormControl>
                </div>
                <div style={{ marginLeft: '8px', marginTop: '46px' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Container style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <Dropzone title='圖片一' getFile={setFile1} />
                            </Container>
                        </Grid>
                        <Grid item xs={3}>
                            <Container style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <Dropzone title='圖片二' getFile={setFile2} />
                            </Container>
                        </Grid>
                        <Grid item xs={3}>
                            <Container style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <Dropzone title='圖片三' getFile={setFile3} />
                            </Container>
                        </Grid>
                        <Grid item xs={3}>
                            <Container style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <Dropzone title='圖片四' getFile={setFile4} />
                            </Container>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ marginLeft: '8px', marginTop: '46px' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div style={{ width: '100%' }}>
                                <SpecTable getSpec={setSpec} />
                            </div>
                        </Grid>
                    </Grid>
                    <div style={{ margin: '8px' }}>
                        <FormControl>
                            <FormHelperText style={{ fontSize: '16px', color: 'Black', fontWeight:'bold' }}>網頁是否顯示規格：</FormHelperText>
                            <Select
                                value={values.ShowSpec}
                                onChange={handleShowSpec}
                            >
                                <MenuItem value={1}>是</MenuItem>
                                <MenuItem value={0}>否</MenuItem>
                            </Select>
                            <FormHelperText style={{ color: 'Red' }}>(*當商品類型為「多項商品展示」時一律不顯示規格)</FormHelperText>
                        </FormControl>
                    </div>
                </div>

                <div style={{ marginLeft: '8px', marginTop: '46px' }}>
                    <Button variant="contained" color="primary" size="large" type="submit" startIcon={<SaveIcon />}>
                        儲存
                    </Button>

                    <Link style={{ textDecoration: 'none', color: '#fff' }} to={"/AdminGotonWeb/PCM"}>
                        <Button variant="contained" color="secondary" size="large" style={{ marginLeft: '8px' }} startIcon={<CancelIcon />}>
                            取消
                        </Button>
                    </Link>

                </div>

            </form>


        </>
    );
}