import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { getAllUser, setUser } from "../API/api.js"

import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { IsLogin, Logout, getToken } from './auth';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '600px',
        },
        formControl: {
            margin: theme.spacing(1),

            minWidth: '600px',
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
}));


export default function PS() {
    const [UserAccount, setUserAccount] = useState('')
    const [UserPWD, setUserPWD] = useState('')
    const [UserPWDChk, setUserPWDChk] = useState('')
    const [CurrUserAccount, setCurrUserAccount] = useState('')
    const classes = useStyles();

    const [loadico, getloadico] = useState(false)

    const history = useHistory();

    const submit = e => {

        if (!IsLogin()) {
            alert('登入時間過期請重新登入');
            return;
        }

        e.preventDefault();

        if (UserAccount.trim() === "" || UserPWD.trim() === "" || UserPWDChk.trim() === "") {
            alert('請輸入帳號密碼');
            return;
        }

        if (UserPWD.trim() !== UserPWDChk.trim()) {
            alert('再次輸入密碼不符');
            return;
        }

        const userData = {
            UserAccount: UserAccount.trim(),
            UserPWD: UserPWD.trim()
        }

        getloadico(true);

        setUser(userData, getToken())
            .then(res => {
                if (res.data) {
                    alert('帳號及碼密更新成功，請重新登入');
                    Logout();
                    if (!IsLogin()) {
                        history.push("/Login");
                    }
                }
            })
            .catch(err => {
                alert('帳號及碼密更新失敗');
                console.log('err', err);
            })
            .finally(() => {
                getloadico(false);
                setUserAccount("");
                setUserPWD("");
                setUserPWDChk("");
            })


    }

 
    const getList = () => {
       
        getAllUser(getToken())
            .then(res => {
                setCurrUserAccount(res.data.UserAccount);
            })
            .catch(err => {
                console.log('err', err);
            })

    }

    useEffect(() => {

        try {
            getloadico(true);
            getList();

        } catch (error) {
            console.error(error);

        } finally {
            getloadico(false)
        }


    }, []);

    return (
        <>
            <Backdrop open={loadico} className={classes.backdrop}>
                <CircularProgress color="secondary" />
            </Backdrop>
            <form className={classes.root} autoComplete="off" onSubmit={submit}>
                <h2>修改帳號密碼</h2>
                <div style={{ margin: '8px' }}>
                    <TextField
                        id="currUserAccount"
                        label="目前帳號"
                        helperText=""
                        onChange={event => setCurrUserAccount(event.target.value)}
                        value={CurrUserAccount}
                        disabled
                    />
                </div>
                <div style={{ margin: '8px' }}>
                    <TextField
                        id="UserAccount"
                        label="新帳號"
                        onChange={event => setUserAccount(event.target.value)}
                        value={UserAccount}
                        required
                    />
                </div>
                <div style={{ margin: '8px' }}>
                    <TextField
                        id="UserPWD"
                        label="新密碼"
                        type="password"
                        onChange={event => setUserPWD(event.target.value)}
                        value={UserPWD}
                        required
                    />
                </div>
                <div style={{ margin: '8px' }}>
                    <TextField
                        id="UserPWDChk"
                        label="重覆輸入新密碼"
                        type="password"
                        onChange={event => setUserPWDChk(event.target.value)}
                        value={UserPWDChk}
                        required
                    />
                </div>

                <div style={{ marginLeft: '8px', marginTop: '46px' }}>

                    <Button variant="contained"  color="primary" type="submit" size="large" startIcon={<SaveIcon />}>
                        儲存
                    </Button>


                </div>

            </form>

        </>

    );
}